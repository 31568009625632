import { api } from "@/api-client";
import { HealthInspectionListFilters } from "./HealthInspectionListFilters";
import moment, { FromTo } from "moment-timezone";

export type HealthInspectionMetricFiltersModelValue = {
    dateRange: FromTo;
    filters: HealthInspectionListFilters;
    timeUnit: api.MetricTimeUnit;
};

export const DEFAULT_FILTERS: HealthInspectionMetricFiltersModelValue = {
    dateRange: {
        from: moment().startOf("year"),
        to: moment(),
    },
    filters: new HealthInspectionListFilters(),
    timeUnit: api.MetricTimeUnit.Months,
};
