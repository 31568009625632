import { api } from "@/api-client";
import { RouteLocationNormalized } from "vue-router";
import workTypeTranslator from "@/features/WorkTypeTranslator";
import vehicleCategoryTranslator from "@/features/VehicleCategoryTranslator";
import vehicleBodyTypes from "@/features/VehicleBodyTypes";
import { FromTo } from "moment-timezone";

abstract class IncidentFilter {
    abstract label: string;
    abstract options: string[];
    selectedOptions: string[] = [];

    removeOption(removedOption: string) {
        this.selectedOptions = this.selectedOptions.filter((x) => x !== removedOption);
    }

    clear() {
        this.selectedOptions = [];
    }
}

// incident filters
// there is no label/value separation because the multi-select form doesn't support it
// instead we guarantee that each selection option string is unique and convert back from it
// upon creating the filter query for the API endpoint

export class IncidentStatusFilter extends IncidentFilter {
    label = "Olek";
    options = ["Töös", "Lõpetatud", "Kinnitatud", "Automaatselt kinnitatud", "Kustutatud"];
    apiOptions = [
        api.FilterByIncidentStatus.InProgress,
        api.FilterByIncidentStatus.Completed,
        api.FilterByIncidentStatus.Confirmed,
        api.FilterByIncidentStatus.AutomaticallyConfirmed,
        api.FilterByIncidentStatus.Deleted,
    ];

    constructor() {
        super();
        this.selectedOptions = this.options.slice(0, 4);
    }

    toInputModelFilter() {
        return [...Array(this.options.length).keys()].map((i) => (this.selectedOptions.includes(this.options[i]) ? this.apiOptions[i] : undefined)).filter((x) => x != null);
    }
}

export class IncidentTypeGroupFilter extends IncidentFilter {
    label = "Liik";
    options = [];

    incidentTypeGroups: api.IIncidentTypeGroupViewModel[];

    constructor(incidentTypeGroups: api.IIncidentTypeGroupViewModel[]) {
        super();
        this.incidentTypeGroups = incidentTypeGroups;
        // @ts-expect-error
        this.options = incidentTypeGroups.map((tg) => tg.name);
    }

    toInputModelFilter() {
        const selected = this.incidentTypeGroups.filter((tg) => this.selectedOptions.includes(tg.name));
        return selected.map((x) => x.id);
    }
}

export class IncidentTypeFilter extends IncidentFilter {
    label = "Alamliik";
    options = [];

    incidentTypes: api.IIncidentTypeViewModel[];

    constructor(incidentTypeGroups: api.IIncidentTypeGroupViewModel[]) {
        super();
        this.incidentTypes = incidentTypeGroups.flatMap((tg) => tg.incidentTypes);
        // @ts-expect-error
        this.options = this.incidentTypes.map((t) => t.name).filter((name) => name !== "Muu juhtum");
    }

    toInputModelFilter() {
        const selected = this.incidentTypes.filter((t) => this.selectedOptions.includes(t.name));
        return selected.map((x) => x.id);
    }
}

export class IncidentWorkTypeFilter extends IncidentFilter {
    label = "Tööliik";
    options = [];

    constructor() {
        super();
        // @ts-expect-error
        this.options = Object.keys(workTypeTranslator.all);
    }

    toInputModelFilter() {
        return this.selectedOptions.map((wt) => workTypeTranslator.all[wt as keyof typeof workTypeTranslator.all] as api.WorkType);
    }
}

export class IncidentWorkLineNumberFilter extends IncidentFilter {
    label = "Liini nr";
    options = [];

    constructor(lineNumbers: string[]) {
        super();
        // @ts-expect-error
        this.options = lineNumbers;
    }

    toInputModelFilter() {
        return this.selectedOptions;
    }
}

export class IncidentVehicleTypeFilter extends IncidentFilter {
    label = "Sõidukiliik";
    options: string[] = [];

    constructor() {
        super();

        const categories = Object.values(api.VehicleCategory).filter((x): x is api.VehicleCategory => typeof x !== "string");

        const product = vehicleBodyTypes
            .all()
            .map((bodyType) => {
                return categories.map((category) => {
                    return new api.VehicleTypeViewModel({ category: category, type: bodyType });
                });
            })
            .flat();

        this.options = product.map((x) => x.label()).sort();
    }

    toInputModelFilter() {
        const selected = this.selectedOptions.map((vt) => (vt.includes("(") ? ([vt.split(" (")[0], vt.split(" (")[1].slice(0, -1)] as const) : ([vt, undefined] as const)));
        return selected.map(
            ([category, vehicleType]) =>
                new api.VehicleTypeFilterItem({
                    category: vehicleCategoryTranslator.stringToVehicleCategory(category),
                    bodyType: vehicleType,
                })
        );
    }
}

export class IncidentRegistrarFilter extends IncidentFilter implements IncidentFilter {
    label = "Registreerija";
    options = [];

    registrars: api.IRegistrarOption[] = [];

    constructor(registrars: api.IRegistrarOption[]) {
        super();
        this.registrars = registrars;

        // @ts-expect-error
        this.options = registrars.map((registrar) => this.format(registrar));
        // @ts-expect-error
        this.options.push("Tundmatu registreerija");
    }

    toInputModelFilter() {
        // @ts-expect-error
        return this.selectedOptions.map((selected) => (selected === "Tundmatu registreerija" ? 0 : this.registrars.find((registrar) => this.format(registrar) == selected).id));
    }

    format(registrar: api.RegistrarOption) {
        return `${registrar.name} (#${registrar.employeeNumber?.padStart(4, "0")})`;
    }
}

export class IncidentHasOperationsFilter extends IncidentFilter implements IncidentFilter {
    label = "Tüüp";
    options = ["Teade", "Juhtum"];

    toInputModelFilter() {
        if (this.selectedOptions.length !== 1) return undefined;
        return this.selectedOptions[0] === "Juhtum";
    }
}

export class IncidentInjuredPersonFilter extends IncidentFilter implements IncidentFilter {
    label = "Vigastatud inimene";
    options = ["Jah", "Ei"];

    toInputModelFilter() {
        if (this.selectedOptions.length !== 1) return undefined;
        return this.selectedOptions[0] === "Jah";
    }
}

export class IncidentDriverDepartmentsFilter extends IncidentFilter {
    label = "Osakond";
    options: string[] = [];

    constructor(departments: string[]) {
        super();
        this.options = departments;
    }

    toInputModelFilter() {
        return this.selectedOptions;
    }
}

type SortIncidentsBy = {
    [key: string]: api.SortIncidentsBy;
};

export type IncidentFilters = {
    incidentStatuses?: IncidentStatusFilter;
    incidentTypeGroups?: IncidentTypeGroupFilter;
    incidentTypes?: IncidentTypeFilter;
    incidentWorkTypes?: IncidentWorkTypeFilter;
    incidentWorkLineNumbers?: IncidentWorkLineNumberFilter;
    incidentVehicleTypes?: IncidentVehicleTypeFilter;
    incidentRegistrars?: IncidentRegistrarFilter;
    incidentDriverDepartments?: IncidentDriverDepartmentsFilter;
};

type SessionStorageIncidentFilters = IncidentFilters & { searchString: string; dateRange: FromTo };

export class IncidentsListFilters {
    filters: IncidentFilters = {};

    static readonly STORAGE_KEY = "IncidentListFilters";

    map(route: RouteLocationNormalized) {
        const hasRoute = Object.keys(route.query).length > 0;
        const sessionStorageData = sessionStorage.getItem(IncidentsListFilters.STORAGE_KEY);

        if (hasRoute && sessionStorageData == null) {
            this.getFilters().forEach((filter) => {
                filter.selectedOptions = route.query[filter.label] ? String(route.query[filter.label]).split(",") : filter.selectedOptions;
            });
        } else if (sessionStorageData != null) {
            const sessionData: IncidentFilter[] = JSON.parse(sessionStorageData);
            Object.entries(sessionData).forEach(([key, value]) => {
                const filterKey = key as keyof typeof this.filters;
                if (key in this.filters && this.filters[filterKey] != null) {
                    this.filters[filterKey]!.selectedOptions = value.selectedOptions;
                }
            });
        }
    }

    createQuery() {
        const query: { [key: string]: string[] } = {};
        // @ts-expect-error
        this.getFilters().forEach((filter) => (query[filter.label] = filter.selectedOptions.length ? filter.selectedOptions : undefined));
        return query;
    }

    getFilters(): IncidentFilter[] {
        return Object.values(this.filters);
    }

    async loadData(listModel: api.IncidentListViewModel) {
        const incidentsClient = new api.IncidentsClient();
        const incidentTypeGroups = await incidentsClient.incidentTypes();

        this.filters = {
            incidentStatuses: new IncidentStatusFilter(),
            incidentTypeGroups: new IncidentTypeGroupFilter(incidentTypeGroups),
            incidentTypes: new IncidentTypeFilter(incidentTypeGroups),
            incidentWorkTypes: new IncidentWorkTypeFilter(),
            incidentWorkLineNumbers: new IncidentWorkLineNumberFilter(listModel.lineNumbersOptions),
            incidentVehicleTypes: new IncidentVehicleTypeFilter(),
            incidentRegistrars: new IncidentRegistrarFilter(listModel.registrarOptions),
            incidentDriverDepartments: new IncidentDriverDepartmentsFilter(listModel.driverDepartmentOptions),
        };
    }

    getSessionStorage() {
        if (sessionStorage.getItem(IncidentsListFilters.STORAGE_KEY) != null) {
            return JSON.parse(sessionStorage.getItem(IncidentsListFilters.STORAGE_KEY)!) as SessionStorageIncidentFilters;
        }

        return undefined;
    }

    saveToSessionStorage(searchString: string, dateRange?: FromTo) {
        const sessionData = { ...this.filters, searchString, dateRange };
        sessionStorage.setItem(IncidentsListFilters.STORAGE_KEY, JSON.stringify(sessionData));
    }

    clearFilters() {
        this.getFilters().forEach((filter) => filter.clear());
        sessionStorage.removeItem(IncidentsListFilters.STORAGE_KEY);
    }

    static sortBy: SortIncidentsBy = {
        incidentId: api.SortIncidentsBy.RegistrationTime,
        registration: api.SortIncidentsBy.RegistrationTime,
        typeGroup: api.SortIncidentsBy.TypeGroup,
        category: api.SortIncidentsBy.Category,
        lineNumber: api.SortIncidentsBy.LineNumber,
        vehicle: api.SortIncidentsBy.VehicleId,
        driver: api.SortIncidentsBy.DriverName,
    };
}
