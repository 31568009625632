import type { RouteLocationNormalized } from "vue-router";
import { IQuasarTablePagination } from "@/features/departuresTable/IQuasarTablePagination";

export default class DeparturesTableViewPagination {
    sortBy?: string;
    descending?: boolean;
    page?: number;
    rowsPerPage?: number;
    rowsNumber?: number;

    constructor() {
        this.descending = false;
        this.page = 1;
        this.rowsPerPage = 15;
    }

    mapFromRoute(route: RouteLocationNormalized) {
        this.sortBy = route.query.sorteeri?.toString();
        this.descending = route.query.kahanevalt === "true";
        this.page = Number(route.query.leht ?? 1);
        this.rowsPerPage = Number(route.query.kirjeidLehel ?? 15);
    }

    createQuery() {
        return {
            sorteeri: this.sortBy,
            kahanevalt: this.descending?.toString(),
            leht: this.page?.toString(),
            kirjeidLehel: this.rowsPerPage?.toString(),
        };
    }

    updatePagination(quasarPagination: IQuasarTablePagination): DeparturesTableViewPagination {
        const pagination = new DeparturesTableViewPagination();

        pagination.descending = quasarPagination.descending;
        pagination.page = quasarPagination.page;
        pagination.rowsNumber = quasarPagination.rowsNumber;
        pagination.rowsPerPage = quasarPagination.rowsPerPage;
        pagination.sortBy = quasarPagination.sortBy;

        return pagination;
    }
}
