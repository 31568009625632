import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";
import { api } from "@/api-client";

export class UpdateExpiredMessage extends HubMessage<UpdateExpiredMessage> {
    updateNames!: api.ExternalServiceOperationName[];

    public override overrideFromJSON(data: any): UpdateExpiredMessage {
        const result = new UpdateExpiredMessage();
        result.updateNames = data["updateNames"];
        return result;
    }
}

export type OnUpdatesExpiredNotification = (message: UpdateExpiredMessage) => void;

export class ExpiredUpdatesHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("expired-updates-hub");
    }

    public async open(onNotify: OnUpdatesExpiredNotification) {
        await super.onMessage(UpdateExpiredMessage, "Notify", onNotify);
        await super.start();

        return this;
    }

    public async close() {
        await super.stop();
    }
}
