import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ad2d836e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading-actions" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col d-flex align-items-center" }
const _hoisted_4 = { class: "col-auto" }
const _hoisted_5 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
        ])
      ])
    ])
  ]))
}