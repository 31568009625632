import { type App } from "vue";
import { api } from "@/api-client";
import { getParentElementWithByClass } from "@/helpers/Utils";
import validatableDirectiveHelpers from "@/directives/ValidatableDirectiveHelpers";

export default function registerDirectives(app: App<Element>) {
    app.directive("validatable", {
        mounted(element, directive, vNode) {
            let propertyName: string | null = directive.value || validatableDirectiveHelpers.getPropertyName(vNode);
            if (!propertyName) {
                return;
            }

            const component = directive.instance as any;

            if (component.prefix) {
                propertyName = component.prefix + propertyName;
            }
            let errorMessageContainer: HTMLElement;

            const validationErrorsHelper = component.validationErrorsHelper;
            (directive.instance as any).unwatchHandlers = (directive.instance as any).unwatchHandlers || [];
            (directive.instance as any).unwatchHandlers.push({
                element: element,
                unwatch: component!.$watch(
                    () => validationErrorsHelper.validationErrors.messages,
                    () => {
                        if (propertyName == null) return;

                        const validationErrors = validationErrorsHelper.validationErrors as api.ResultError;
                        const errors = validationErrorsHelper.getValidationErrorsFor(propertyName.toLowerCase()) as string[];

                        const isQuasarElement =
                            element.classList.contains("q-field") || element.classList.contains("app-datetime-picker") || element.classList.contains("card-list-radios--horizontal");
                        const alertElement = getParentElementWithByClass(element, "app-alert") as HTMLElement;

                        if (isQuasarElement) {
                            validatableDirectiveHelpers.addQuasarElementErrors(element, propertyName, errors, validationErrors);
                            return;
                        }
                        if (alertElement) {
                            errorMessageContainer = validatableDirectiveHelpers.clearErrorMessageContainerAndAddErrorsToAlertElement(
                                element,
                                alertElement,
                                errorMessageContainer,
                                propertyName,
                                errors,
                                validationErrors
                            );
                            return;
                        }
                        errorMessageContainer = validatableDirectiveHelpers.clearErrorMessageContainerAndAddBootstrapErrorsToElement(
                            element,
                            errorMessageContainer,
                            propertyName,
                            errors,
                            validationErrors
                        );
                        return;
                    },
                    { deep: true }
                ),
            });
        },
        beforeUnmount(element, binding, vNode, prevVnode) {
            if ((binding.instance as any).unwatchHandlers) {
                (binding.instance as any).unwatchHandlers.filter((handler: { element: any }) => handler.element == element).forEach((handler: { unwatch: () => void }) => handler.unwatch());
                (binding.instance as any).unwatchHandlers = [];
            }
        },
    });
}
