import { getJobWithReadOnlyTags } from "../../shared/utils";

export type IncidentJobsModel = {
    jobIds: number[];
    driverIds: number[];
    vehicleIds: number[];
};

export type JobWithReadOnlyTags = ReturnType<typeof getJobWithReadOnlyTags>;

/*
 * Add the job and all of its drivers/vehicles to the model.
 */
export function addJob(modelValue: IncidentJobsModel, job: JobWithReadOnlyTags): IncidentJobsModel {
    const driverIds = job?.servingDriversAndVehicles.map((x) => x.driver.driverId) ?? [];
    const vehicleIds = job?.servingDriversAndVehicles.map((x) => x.vehicle?.id).filter((x) => x != null) ?? [];

    return {
        jobIds: [...new Set([...modelValue.jobIds, job.jobId])],
        driverIds: [...new Set([...modelValue.driverIds, ...driverIds])],
        vehicleIds: [...new Set([...modelValue.vehicleIds, ...vehicleIds])],
    };
}

/*
 * Remove the job and all of its drivers/vehicles from the model, except for the drivers/vehicles that are related via other jobs.
 */
export function removeJob(modelValue: IncidentJobsModel, job: JobWithReadOnlyTags, jobs: JobWithReadOnlyTags[]): IncidentJobsModel {
    const otherSelectedDvs = getSelectedDriversAndVehicles(
        modelValue,
        jobs.filter((job) => job.jobId != job.jobId)
    );

    const driverIds = job?.servingDriversAndVehicles.map((x) => x.driver.driverId) ?? [];
    const vehicleIds = job?.servingDriversAndVehicles.map((x) => x.vehicle?.id).filter((x) => x != null) ?? [];

    const driverIdsToRemove = driverIds.filter((driverId) => otherSelectedDvs.every((dv) => dv.driver.driverId !== driverId));
    const vehicleIdsToRemove = vehicleIds.filter((vehicleId) => otherSelectedDvs.every((dv) => dv.vehicle?.id !== vehicleId));

    return {
        jobIds: modelValue.jobIds.filter((x) => x !== job.jobId),
        driverIds: modelValue.driverIds.filter((x) => !driverIdsToRemove.includes(x)),
        vehicleIds: modelValue.vehicleIds.filter((x) => !vehicleIdsToRemove.includes(x)),
    };
}

/*
 * Add the driver and vehicle to the job.
 */
export function addDriverAndVehicle(modelValue: IncidentJobsModel, job: JobWithReadOnlyTags, dvId: number, jobs: JobWithReadOnlyTags[]): IncidentJobsModel {
    if (!modelValue.jobIds.includes(job.jobId)) {
        return modelValue; // job not selected
    }

    const dv = job.servingDriversAndVehicles.find((dv) => dv.id === dvId);
    if (dv == null) {
        return modelValue; // DV not found within job
    }

    return {
        jobIds: modelValue.jobIds,
        driverIds: [...new Set([...modelValue.driverIds, dv.driver.driverId])],
        vehicleIds: [...new Set(modelValue.vehicleIds.concat(dv.vehicle?.id ?? []))],
    };
}

/*
 * Remove the driver & vehicle from the job unless it is related via other jobs.
 */
export function removeDriverAndVehicle(modelValue: IncidentJobsModel, job: JobWithReadOnlyTags, dvId: number, jobs: JobWithReadOnlyTags[]) {
    const otherSelectedDvs = getSelectedDriversAndVehicles(modelValue, jobs).filter((dv) => dv.id !== dvId);

    const deletedDv = job.servingDriversAndVehicles.find((dv) => dv.id === dvId);
    if (deletedDv == null) {
        return modelValue;
    }

    const driverIdsToRemove = otherSelectedDvs.some((dv) => dv.driver.driverId === deletedDv.driver.driverId) ? [] : [deletedDv.driver.driverId];
    const vehicleIdsToRemove = deletedDv.vehicle != null && otherSelectedDvs.some((dv) => dv.vehicle?.id === deletedDv.vehicle?.id) ? [] : [deletedDv.vehicle?.id];

    return {
        jobIds: modelValue.jobIds,
        driverIds: modelValue.driverIds.filter((x) => !driverIdsToRemove.includes(x)),
        vehicleIds: modelValue.vehicleIds.filter((x) => !vehicleIdsToRemove.includes(x)),
    };
}

function getSelectedDriversAndVehicles(modelValue: IncidentJobsModel, jobs: JobWithReadOnlyTags[]) {
    return jobs
        .filter((job) => modelValue.jobIds.includes(job.jobId))
        .flatMap((job) => job.servingDriversAndVehicles)
        .filter((dv) => modelValue.driverIds.includes(dv.driver.driverId) && dv.vehicle != null && modelValue.vehicleIds.includes(dv.vehicle.id));
}
