import script from "./ParkingLotModal.vue?vue&type=script&lang=ts&setup=true"
export * from "./ParkingLotModal.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QSelect,QItem,QItemSection,QCheckbox});
