import { api } from "@/api-client";
import moment from "moment-timezone";

class JobsMonitoringHelpers {
    public sortByDeviation = (a: api.IDeviationModel, b: api.IDeviationModel) => {
        return a.deviationInMinutes - b.deviationInMinutes;
    };

    public getDeviationAwareTime(time: Date, deviation: api.IDeviationModel | null) {
        if (deviation?.isEarly) {
            return moment(time).add(deviation.deviationInMinutes, "minutes").toDate();
        }
        if (deviation?.isLate) {
            return moment(time).subtract(deviation.deviationInMinutes, "minutes").toDate();
        }
        return time;
    }

    public getDeviationTimeSpanString(deviation: api.IDeviationModel | null) {
        if (deviation == null) {
            return "";
        }

        return `${deviation.isEarly ? "-" : deviation.isLate ? "+" : ""} ${this.getMinutesAsTimespan(deviation.deviationInMinutes)}`;
    }

    public getMinutesAsTimespan(minutes: number) {
        const absoluteMinutes = Math.abs(minutes);
        const timeSpan = moment.duration(absoluteMinutes, "minutes");
        return `${Math.floor(timeSpan.asHours()).toFixed().padStart(2, "0")}:${String(timeSpan.minutes()).padStart(2, "0")}`;
    }

    public getDeviationTimeSpanAsMinutesString(deviation: api.IDeviationModel | null) {
        if (deviation == null) {
            return "";
        }
        return `${deviation.isEarly ? "-" : ""}  ${this.formatMinutesToHoursAndMinutes(deviation.deviationInMinutes)}`;
    }

    public formatMinutesToHoursAndMinutes(minutes: number): string {
        const absoluteDeviation = Math.abs(minutes);
        const timeSpan = moment.duration(absoluteDeviation, "minutes");
        return `${timeSpan.hours() * 60 + timeSpan.minutes()}m ${timeSpan.seconds()}s`;
    }
}

const jobsMonitoringHelpers = new JobsMonitoringHelpers();
export default jobsMonitoringHelpers;
