import { ListFilters } from "@/components/ListFilters";
import { RouteLocationNormalized } from "vue-router";
import Router from "@/router";
import { api } from "@/api-client";
import workTypeTranslator from "@/features/WorkTypeTranslator";
import moment from "moment-timezone";
import { DEFAULT_PAGE_SIZE } from "@/shared/constants";

export interface IReservesListFilters {
    searchString: string;
    vehicleTypes: string[];
    workTypes: string[];
    jobCompletionTypes: string[];
    shiftFrom?: Date;
    shiftTo?: Date;
}

export class ReservesListFilters extends ListFilters implements IReservesListFilters {
    searchString = "";

    vehicleTypes: string[] = [];
    workTypes: string[] = [];
    jobCompletionTypes: string[];
    shiftFrom: Date | undefined;
    shiftTo: Date | undefined;

    static NoVehicle = "Sõiduk puudub";
    static NoWorkType = "Tööliik puudub";

    static readonly STORAGE_KEY = "ReservesListFilters";

    static readonly JOB_COMPLETION_OPTIONS = ["Lõppenud", "Töös", "Alustab tulevikus"] as const;

    constructor(data?: Partial<IReservesListFilters>) {
        super();
        this.searchString = data?.searchString ?? "";
        this.vehicleTypes = data?.vehicleTypes ?? [];
        this.workTypes = data?.workTypes ?? [];
        this.jobCompletionTypes = data?.jobCompletionTypes ?? [];
        this.shiftFrom = data?.shiftFrom;
        this.shiftTo = data?.shiftTo;
    }

    map(route: RouteLocationNormalized) {
        const hasRoute = Object.keys(route.query).length > 0;
        const storage = sessionStorage.getItem(ReservesListFilters.STORAGE_KEY);

        if (hasRoute) {
            this.searchString = route.query.searchString ? (route.query.searchString as string) : "";
            this.vehicleTypes = route.query.vehicleTypes ? String(route.query.vehicleTypes).split(",") : [];
            this.workTypes = route.query.workTypes ? String(route.query.workTypes).split(",") : [];
            this.jobCompletionTypes = route.query.jobCompletionTypes ? String(route.query.jobCompletionTypes).split(",") : [];
            this.shiftFrom = route.query.shiftFrom ? moment(route.query.shiftFrom as string).toDate() : undefined;
            this.shiftTo = route.query.shiftTo ? moment(route.query.shiftTo as string).toDate() : undefined;
        } else if (storage) {
            const data = JSON.parse(storage);
            this.searchString = data.searchString ?? "";
            this.vehicleTypes = data.vehicleTypes ?? [];
            this.workTypes = data.workTypes ?? [];
            this.jobCompletionTypes = data.jobCompletionTypes ?? [];
            this.shiftFrom = data.shiftFrom;
            this.shiftTo = data.shiftTo;
        }
    }

    createQuery() {
        return {
            searchString: this.searchString ? this.searchString : undefined,
            vehicleTypes: this.vehicleTypes.length ? this.vehicleTypes : undefined,
            workTypes: this.workTypes.length ? this.workTypes : undefined,
            jobCompletionTypes: this.jobCompletionTypes.length ? this.jobCompletionTypes : undefined,
            shiftFrom: this.shiftFrom ? moment(this.shiftFrom).toISOString() : undefined,
            shiftTo: this.shiftTo ? moment(this.shiftTo).toISOString() : undefined,
        };
    }

    async persistFilters(router: typeof Router, { sortBy, descending, rowsPerPage, page }: { sortBy: string; descending: boolean; rowsPerPage: number; page: number }) {
        const query = {
            ...this.createQuery(),
            sortBy: sortBy === "shiftStart" ? undefined : sortBy,
            descending: descending ? descending.toString() : undefined,
            rowsPerPage: rowsPerPage != null && rowsPerPage !== DEFAULT_PAGE_SIZE ? rowsPerPage : undefined,
            page: page > 1 ? page : undefined,
        };

        sessionStorage.setItem(ReservesListFilters.STORAGE_KEY, JSON.stringify(query));

        await router.replace({
            query: query,
        });
    }

    clearAllFilters(clearSearch = true): ReservesListFilters {
        sessionStorage.removeItem(ReservesListFilters.STORAGE_KEY);

        this.vehicleTypes = [];
        this.workTypes = [];
        this.jobCompletionTypes = [];
        this.shiftFrom = undefined;
        this.shiftTo = undefined;

        if (clearSearch) {
            this.searchString = "";
        }

        return this;
    }

    applyOn(jobs: api.IReserveJobViewModel[]): api.IReserveJobViewModel[] {
        jobs = this.filterBySearchString(jobs);
        jobs = this.filterByVehicleType(jobs);
        jobs = this.filterByWorkType(jobs);
        jobs = this.filterByJobCompletionType(jobs);
        jobs = this.filterByShiftTime(jobs);
        return jobs;
    }

    filterBySearchString(jobs: api.IReserveJobViewModel[]) {
        return jobs.filter((job) =>
            this.searchString
                ? job.servingDriverAndVehicle.driver.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
                  job.servingDriverAndVehicle.driver.tableNumber.toLowerCase().startsWith(this.searchString.toLowerCase()) ||
                  (job.servingDriverAndVehicle.vehicle?.garageNumber ?? "").toLowerCase().startsWith(this.searchString.toLowerCase())
                : true
        );
    }

    filterByVehicleType(jobs: api.IReserveJobViewModel[]) {
        return jobs.filter((job) =>
            this.vehicleTypes.length
                ? this.vehicleTypes.includes(job.servingDriverAndVehicle.vehicle?.type == null ? ReservesListFilters.NoVehicle : job.servingDriverAndVehicle.vehicle.type.label())
                : true
        );
    }

    filterByWorkType(jobs: api.IReserveJobViewModel[]) {
        return jobs.filter((job) => (this.workTypes.length ? this.workTypes.includes(workTypeTranslator.translate(job.servingDriverAndVehicle.workType)) : true));
    }

    filterByJobCompletionType(jobs: api.IReserveJobViewModel[]) {
        return jobs.filter((job) => {
            if (this.jobCompletionTypes.length === 0) {
                return true;
            }

            if (this.jobCompletionTypes.includes("Lõppenud") && moment(job.shiftEnd).isBefore(moment())) {
                return true;
            }

            if (this.jobCompletionTypes.includes("Töös") && moment(job.shiftStart).isBefore(moment()) && moment(job.shiftEnd).isAfter(moment())) {
                return true;
            }

            if (this.jobCompletionTypes.includes("Alustab tulevikus") && moment(job.shiftStart).isAfter(moment())) {
                return true;
            }

            return false;
        });
    }

    filterByShiftTime(jobs: api.IReserveJobViewModel[]) {
        return jobs.filter((job) => (this.shiftFrom ? moment(job.shiftStart) >= moment(this.shiftFrom) : true)).filter((job) => (this.shiftTo ? moment(job.shiftEnd) <= moment(this.shiftTo) : true));
    }
}
