export class HTMLBuilder {
    // @ts-expect-error
    private _htmlElement: HTMLElement = null;

    create(tag: string) {
        this._htmlElement = document.createElement(tag.trim());
        return this;
    }

    withClass(className: string) {
        className
            .trim()
            .split(" ")
            .forEach((c) => this._htmlElement.classList.add(c));
        return this;
    }

    withAttribute(name: string, value: string) {
        this._htmlElement.setAttribute(name.trim(), value.trim());
        return this;
    }

    withText(text: string) {
        this._htmlElement.innerText = text;
        return this;
    }

    build() {
        return this._htmlElement;
    }
}

const htmlBuilder = new HTMLBuilder();
export default htmlBuilder;
