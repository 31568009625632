import { api } from "@/api-client";

export class DownloadHelper {
    public download(response: api.FileResponse): void {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.classList.add("d-none");
        const fileUrl = window.URL.createObjectURL(response.data!);
        a.href = fileUrl;
        a.download = response.fileName!;
        a.click();
        window.URL.revokeObjectURL(fileUrl);
    }

    public openInNewTab(response: api.FileResponse): void {
        const fileUrl = window.URL.createObjectURL(response.data!);
        const newTab = window.open(fileUrl, "_blank");
    }
}

const downloadHelper = new DownloadHelper();
export default downloadHelper;
