export interface ILoader {
    get loading(): boolean;
    startLoading(): void;
    endLoading(): void;
}

export default class Loader implements ILoader {
    constructor(private isPreloading = false) {}

    private loadingCount = 0;

    public get loading() {
        return this.isPreloading || this.loadingCount > 0;
    }

    public startLoading() {
        this.isPreloading = false;
        this.loadingCount++;
    }

    public endLoading() {
        this.isPreloading = false;
        this.loadingCount--;
        if (this.loadingCount < 0) {
            this.loadingCount = 0;
        }
    }
}
