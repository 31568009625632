import { api } from "@/api-client";
import * as turf from "@turf/helpers";

function parseJson(json: string) {
    try {
        return JSON.parse(json) as unknown;
    } catch (_) {
        return undefined;
    }
}

function isNonEmptyArray(value: unknown): value is unknown[] {
    return Array.isArray(value) && value.length > 0;
}

// could use io-ts library, but this is simple enough
function isMultiPolygonGeoJson(geoJson: unknown): geoJson is turf.MultiPolygon {
    if (typeof geoJson !== "object" || geoJson == null) {
        return false;
    }

    const hasType = "type" in geoJson;
    const hasCoordinates = "coordinates" in geoJson;

    if (!hasType || !hasCoordinates) {
        return false;
    }

    if (geoJson.type !== "MultiPolygon") {
        return false;
    }

    // validate that the coordinates are an array of polygons

    if (!isNonEmptyArray(geoJson.coordinates)) {
        return false;
    }

    for (const polygon of geoJson.coordinates) {
        // validate that the polygon is an array of rings
        if (!isArrayOfRings(polygon)) {
            return false;
        }
    }

    return true;
}

function isPolygonGeoJson(geoJson: unknown): geoJson is turf.Polygon {
    if (typeof geoJson !== "object" || geoJson == null) {
        return false;
    }

    const hasType = "type" in geoJson;
    const hasCoordinates = "coordinates" in geoJson;

    if (!hasType || !hasCoordinates) {
        return false;
    }

    if (geoJson.type !== "Polygon") {
        return false;
    }

    // validate that the coordinates are an array of rings

    return isArrayOfRings(geoJson.coordinates);
}

function isArrayOfRings(geoJson: unknown): geoJson is turf.Position[][] {
    if (!isNonEmptyArray(geoJson)) {
        return false;
    }

    if (geoJson.some((ring) => !isNonEmptyArray(ring))) {
        return false;
    }

    for (const ring of geoJson) {
        // validate that the ring is an array of points

        if (!isNonEmptyArray(ring)) {
            return false;
        }

        for (const point of ring) {
            // validate that the point is an array of two numbers (coordinates)

            if (!isNonEmptyArray(point) || point.length !== 2) {
                return false;
            }

            for (const coord of point) {
                if (typeof coord !== "number") {
                    return false;
                }
            }
        }
    }

    return true;
}

export function deserializeMultiPolygonGeoJson(json: string) {
    // deserialize safely into a TS type that makes no assumptions
    const geoJson = parseJson(json);

    if (isMultiPolygonGeoJson(geoJson)) {
        return turf.multiPolygon(geoJson.coordinates);
    }

    if (isPolygonGeoJson(geoJson)) {
        return turf.multiPolygon([geoJson.coordinates]);
    }

    return undefined;
}

export function convertMultiPolygonToApi(multiPolygon: turf.MultiPolygon) {
    const apiMultiPolygon: api.IMultiPolygon = {
        type: "MultiPolygon",
        // swap the coordinates for our API
        coordinates: multiPolygon.coordinates.map((polygon) => polygon.map((ring) => ring.map((point) => [point[1], point[0]]))),
    };

    return api.MultiPolygon.fromJS(apiMultiPolygon);
}

export function serializeApiMultiPolygon(multiPolygon: api.IMultiPolygon) {
    // swap the coordinates for reading
    const swappedMultiPolygon = {
        type: "MultiPolygon",
        coordinates: multiPolygon.coordinates.map((polygon) => polygon.map((ring) => ring.map((point) => [point[1], point[0]]))),
    };

    return JSON.stringify(swappedMultiPolygon, null, 2);
}
