import { api } from "@/api-client";
import moment from "moment-timezone";
import { UnservedTripListFilters } from "./UnservedTripListFilters";

export const DEFAULT_FILTERS = {
    dateRange: {
        from: moment().startOf("year"),
        to: moment(),
    },
    filters: new UnservedTripListFilters(),
    timeUnit: api.MetricTimeUnit.Months,
};
