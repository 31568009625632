
import { defineComponent } from "vue";
import NavTop from "@/components/shared/NavTop.vue";

export default defineComponent({
    name: "App",
    components: {
        NavTop,
    },
});
