import { api } from "@/api-client";

export function translateRepairWorkType(workType: api.VehicleRepairWorkType): string {
    switch (workType) {
        case api.VehicleRepairWorkType.OngoingRepair:
            return "Jooksev remont";
        case api.VehicleRepairWorkType.Maintenance:
            return "Tehnohooldus";
        case api.VehicleRepairWorkType.TramAtVanaLouna:
            return "Tramm (Vana-Lõunas)";
        case api.VehicleRepairWorkType.Warranty:
            return "Garantii";
        case api.VehicleRepairWorkType.CarCrash:
            return "Avarii";
        case api.VehicleRepairWorkType.Trolley:
            return "Troll";
        case api.VehicleRepairWorkType.TramAtKopli:
            return "Tramm";
        case api.VehicleRepairWorkType.Thoreb:
            return "Thorebi Infosüsteem";
        case api.VehicleRepairWorkType.Other:
            return "Muu";
        default:
            workType satisfies never;
            return "Muu";
    }
}

export function translateRepairStatus(status: api.VehicleRepairStatusCodes) {
    switch (status) {
        case api.VehicleRepairStatusCodes.Serviceable:
            return "Korras";
        case api.VehicleRepairStatusCodes.UnprocessedRequestSameProblem:
            return "Korduv probleem";
        case api.VehicleRepairStatusCodes.UnprocessedRequestOperationImpossible:
            return "Sõiduk ei ole kasutatav";
        case api.VehicleRepairStatusCodes.UnprocessedRequestLeavingTheLine:
            return "Sõiduk eemaldatakse liinilt";
        case api.VehicleRepairStatusCodes.UnprocessedRequestAccident:
            return "Avarii";
        case api.VehicleRepairStatusCodes.UnprocessedRequestRepairOrMaintenance:
            return "Remondis";
        case api.VehicleRepairStatusCodes.UnprocessedRequestInfoSystemRepair:
            return "Sõiduki infosüsteem paranduses";
        case api.VehicleRepairStatusCodes.OpenPurchaseOrder:
            return "Probleemid litsentsiga";
        case api.VehicleRepairStatusCodes.OpenPurchaseOrderAndCarNotIssued:
            return "Töökoja tellimus puudub";
        default:
            status satisfies never;
            return "Muu";
    }
}
