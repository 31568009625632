import { api } from "@/api-client";
import { ref } from "vue";

const memoizedDistances = ref<Map<string, number | undefined>>(new Map());

function getDistanceKey(startStopId: number, endStopId: number) {
    return `${startStopId}:${endStopId}`;
}

async function get(startStopId: number, endStopId: number) {
    const cacheKey = getDistanceKey(startStopId, endStopId);
    if (memoizedDistances.value.has(cacheKey)) {
        return memoizedDistances.value.get(cacheKey);
    }

    const client = new api.RedirectionsClient().ignoreNotFound();
    return await client
        .distance(startStopId, endStopId)
        .then((distance) => {
            memoizedDistances.value.set(cacheKey, distance.distanceInKm);
            return distance.distanceInKm;
        })
        .catch((err) => {
            if (err.status === 404) {
                // if we found nothing, check again next time
                // this is useful for cases where two redirections w/ same stops are created one after another (e.g. two operations)
                return undefined;
            }
        });
}

export function useDistances() {
    return {
        get,
    };
}
