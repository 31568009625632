import script from "./LineDeviationOffcanvas.vue?vue&type=script&lang=ts&setup=true"
export * from "./LineDeviationOffcanvas.vue?vue&type=script&lang=ts&setup=true"

import "./LineDeviationOffcanvas.vue?vue&type=style&index=0&id=52a09b68&lang=scss"

const __exports__ = script;

export default __exports__
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTd});
