import { useToast } from "vue-toastification";
import { type ToastOptions } from "vue-toastification/dist/types/types";

class Toasts {
    private readonly toast = useToast();

    private readonly options = {
        timeout: 10000,
        position: "bottom-right",
    } as ToastOptions;

    public success(message: string) {
        this.toast.success(message, this.options as any);
    }

    public info(message: string) {
        this.toast.info(message, this.options as any);
    }

    public warning(message: string) {
        this.toast.warning(message, this.options as any);
    }

    public error(message: string) {
        this.toast.error(message, this.options as any);
    }

    public errors(messages: string[]) {
        for (const error of messages) {
            this.toast.error(error, this.options as any);
        }
    }

    public clear() {
        this.toast.clear();
    }
}

const toasts = new Toasts();
export default toasts;
