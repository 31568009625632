import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";
import { api } from "@/api-client";

export class UpsertIncidentMessage extends HubMessage<UpsertIncidentMessage> {
    incident!: api.RelatedIncidentViewModel;
    relatedJobIds!: number[];

    public override overrideFromJSON(data: any): UpsertIncidentMessage {
        this.incident = api.RelatedIncidentViewModel.fromJS(data["incident"]);
        this.relatedJobIds = data["relatedJobIds"];
        return this;
    }
}
export type OnIncidentUpsert = (message: UpsertIncidentMessage) => void;

export class DeleteIncidentMessage extends HubMessage<DeleteIncidentMessage> {
    incidentId!: number;

    public override overrideFromJSON(data: any): DeleteIncidentMessage {
        this.incidentId = data["incidentId"];
        return this;
    }
}
export type OnIncidentDelete = (message: DeleteIncidentMessage) => void;

type WithIdOrJobId = { id: number } | { jobId: number };

function hasJobIdField(obj: WithIdOrJobId): obj is { jobId: number } {
    return "jobId" in obj;
}

export class IncidentHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("incident-hub");
    }

    public async open(onUpsert: OnIncidentUpsert, onDelete: OnIncidentDelete) {
        await super.onMessage(UpsertIncidentMessage, "Upsert", onUpsert);
        await super.onMessage(DeleteIncidentMessage, "Delete", onDelete);

        await super.start();
        return this;
    }

    public async close() {
        await super.stop();
    }

    public static createOnIncidentUpsertHandler(rows: (WithIdOrJobId & { incidents: api.RelatedIncidentViewModel[] })[]) {
        return (message: UpsertIncidentMessage) => {
            rows.forEach((row) => {
                if (message.relatedJobIds.includes(hasJobIdField(row) ? row.jobId : row.id)) {
                    // update the related incident data in the job row
                    row.incidents = [...row.incidents.filter((incident) => incident.incidentId !== message.incident.incidentId), message.incident];
                } else if (row.incidents.some((incident) => incident.incidentId === message.incident.incidentId)) {
                    // the incident is no longer related to the job row, so remove it
                    row.incidents = row.incidents.filter((incident) => incident.incidentId !== message.incident.incidentId);
                }
            });
        };
    }

    public static createOnIncidentDeleteHandler(rows: (WithIdOrJobId & { incidents: api.RelatedIncidentViewModel[] })[]) {
        return (message: DeleteIncidentMessage) => {
            rows.forEach((row) => {
                row.incidents = row.incidents.filter((incident) => incident.incidentId !== message.incidentId);
            });
        };
    }
}
