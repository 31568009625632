export class RouteMenuItem {
    public routeName: string;
    public routeText: string;
    public params: any;
    public notification?: RouteNotification;
    constructor(routeName: string, routeText: string, params?: object, hasNotification?: boolean) {
        const notification = hasNotification ? new RouteNotification("warning", "danger") : undefined;
        this.routeName = routeName;
        this.routeText = routeText;
        this.params = params;
        this.notification = notification;
    }
}

export class RouteNotification {
    public iconName: string;
    public color: string;

    constructor(iconName: string, color: string) {
        this.iconName = iconName;
        this.color = color;
    }
}
