import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginView from "@/features/authentication/LoginView.vue";
import UnauthorizedView from "@/features/authorization/UnauthorizedView.vue";
import NotFound from "@/features/errors/NotFound.vue";
import ServerError from "@/features/errors/ServerError.vue";
import Forbidden from "@/features/errors/Forbidden.vue";
import PreparationView from "@/features/preparation/departures/PreparationView.vue";
import RegularDepartures from "@/features/preparation/departures/RegularDepartures.vue";
import ReserveDepartures from "@/features/preparation/departures/reserves/ReserveDepartures.vue";
import WorkSchedule from "@/features/documents/WorkSchedule.vue";
import IncidentsView from "@/features/incidents/incidentsList/IncidentsView.vue";
import IncidentView from "@/features/incidents/incidentView/IncidentView.vue";
import VehiclesWrapper from "@/features/vehicles/VehiclesWrapper.vue";
import VehiclesView from "@/features/vehicles/vehicles/VehiclesView.vue";
import VehicleRoutesView from "@/features/vehicles/vehicleRoutes/VehicleRoutesView.vue";
import VehicleRedirectionView from "@/features/vehicles/vehicles/VehicleRedirectionView.vue";
import ReroutesView from "@/features/preparation/reroutes/ReroutesView.vue";
import JobDetails from "@/features/jobs/JobDetails.vue";
import JobGeneralInfo from "@/features/jobs/JobGeneralInfo.vue";
import PublicFiles from "@/features/documents/public/PublicFiles.vue";
import DelaysAndNotes from "@/features/documents/public/DelaysAndNotes.vue";
import DispatcherFiles from "@/features/documents/dispatcher/DispatcherFiles.vue";
import PublicDocumentsView from "@/features/documents/public/PublicDocumentsView.vue";
import DocumentsView from "@/features/documents/dispatcher/DocumentsView.vue";
import WorkOrder from "@/features/documents/WorkOrder.vue";
import PublicWorkOrder from "@/features/documents/public/PublicWorkOrder.vue";
import PublicWorkSchedule from "@/features/documents/public/PublicWorkSchedule.vue";
import LineSchedule from "@/features/documents/LineSchedule.vue";
import PublicLineSchedule from "@/features/documents/public/PublicLineSchedule.vue";
import AdminView from "@/features/admin/AdminWrapper.vue";
import UsersView from "@/features/admin/users/UsersView.vue";
import ReservesWrapper from "@/features/reserves/ReservesWrapper.vue";
import ReserveJobsView from "@/features/reserves/jobs/ReserveJobsView.vue";
import ReserveDriversView from "@/features/reserves/drivers/ReserveDriversView.vue";
import ReserveVehiclesView from "@/features/reserves/vehicles/ReserveVehiclesView.vue";
import JobOrdersView from "@/features/jobOrders/JobOrdersView.vue";
import JobsMonitoringView from "@/features/jobsMonitoring/JobsMonitoringView.vue";
import InProgressJobMonitoring from "@/features/jobsMonitoring/InProgressJobs/InProgressJobMonitoring.vue";
import EndedJobsMonitoring from "@/features/jobsMonitoring/EndedJobs/EndedJobsMonitoring.vue";
import DeparturesMonitoringTable from "@/features/jobsMonitoring/DeparturesMonitoringTable.vue";
import StyleGuideView from "@/features/styleGuide/StyleGuideView.vue";
import DeparturesTableView from "@/features/departuresTable/DeparturesTableView.vue";
import ReservePendingDriversView from "@/features/reserves/pending-drivers/ReservePendingDriversView.vue";
import LineSchedulePrinting from "@/features/preparation/lineSchedules/printing/LineSchedulePrinting.vue";
import ParkingLotsView from "@/features/admin/parkingLots/ParkingLotsView.vue";
import ClassificatorsView from "@/features/admin/classificators/ClassificatorsView.vue";
import ServiceAccountsView from "@/features/admin/users/ServiceAccountsView.vue";
import NotificationGroupsView from "@/features/admin/notificationGroups/NotificationGroupsView.vue";
import ExternalServicesMonitoringView from "@/features/externalServicesMonitoring/ExternalServicesMonitoringView.vue";
import Interruptions from "@/features/externalServicesMonitoring/interruptions/Interruptions.vue";
import UpdateTimes from "@/features/externalServicesMonitoring/updateTimes/UpdateTimes.vue";
import AccountDisplayManagementView from "@/features/admin/users/AccountDisplayManagementView.vue";
import PhoneNumberContactsView from "@/features/admin/phoneNumberContacts/PhoneNumberContactsView.vue";
import SystemParametersView from "@/features/admin/systemParameters/SystemParametersView.vue";
import MetricsWrapper from "@/features/metrics/MetricsWrapper.vue";
import MetricsDashboardView from "@/features/metrics/dashboard/MetricsDashboardView.vue";
import IncidentMetricsView from "@/features/metrics/incidents/IncidentMetricsView.vue";
import userInfo from "@/components/UserInfo";
import JobDelays from "@/features/jobDelays/JobDelays.vue";
import DelayMetricsView from "@/features/metrics/delays/DelayMetricsView.vue";
import ReserveMetricsView from "@/features/metrics/reserves/ReserveMetricsView.vue";
import HealthInspectionMetricsView from "@/features/metrics/healthInspections/HealthInspectionMetricsView.vue";
import UnservedTripMetricsView from "@/features/metrics/unservedTrips/UnservedTripMetricsView.vue";
import PlannedAndServedKmMetricsView from "@/features/metrics/plannedAndServedKm/PlannedAndServedKmMetricsView.vue";
import { api } from "@/api-client";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/sisene",
        alias: "/login",
        name: "login",
        component: LoginView,
        meta: {
            hideMainNavbar: true,
        },
    },
    {
        path: "/ei-leitud",
        name: "not-found",
        component: NotFound,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: NotFound,
    },
    {
        path: "/viga",
        name: "server-error", // Used in ServiceAccount redirection check in beforeEach
        component: ServerError,
    },
    {
        path: "/oigused-puuduvad",
        name: "forbidden",
        component: Forbidden,
    },
    {
        path: "/unauthorized",
        name: "unauthorized",
        component: UnauthorizedView,
    },
    {
        path: "/",
        redirect: (to) => {
            if (userInfo.defaultView === "/") {
                return { name: "preparation" };
            } else {
                const [, paramString] = userInfo.defaultView.split("?");
                const query = groupParamsByKey(new URLSearchParams(paramString));
                return { path: userInfo.defaultView, query: query };
            }
        },
    },
    {
        path: "/ettevalmistamine",
        name: "preparation",
        component: PreparationView,
        redirect: { name: "regular-departures" },
        children: [
            {
                path: "",
                name: "regular-departures",
                component: RegularDepartures,
            },
            {
                path: "reservid",
                name: "reserve-departures",
                component: ReserveDepartures,
            },
            {
                path: "/umbersoidud",
                name: "reroutes",
                component: ReroutesView,
            },
        ],
    },
    {
        path: "/too/:jobId/:driverId",
        name: "job-details",
        component: JobDetails,
        children: [
            {
                path: "uldandmed",
                name: "job-general-info",
                component: JobGeneralInfo,
            },
            {
                path: "dokumendid",
                name: "dispatcher-documents",
                component: DocumentsView,
                children: [
                    {
                        path: "tookorraldus",
                        name: "work-order",
                        component: WorkOrder,
                    },
                    {
                        path: "toograafik",
                        name: "work-schedule",
                        component: WorkSchedule,
                    },
                    {
                        path: "liinigraafik",
                        name: "line-schedule",
                        component: LineSchedule,
                    },
                    {
                        path: "failid",
                        name: "files",
                        component: DispatcherFiles,
                    },
                ],
            },
        ],
    },
    {
        path: "/soidukid",
        name: "vehicles",
        component: VehiclesWrapper,
        children: [
            {
                // empty path is used for the 'vehicles' view
                path: "",
                name: "vehicles-table",
                component: VehiclesView,
            },
            {
                path: "trass",
                name: "vehicle-routes-table",
                component: VehicleRoutesView,
            },
        ],
    },
    {
        path: "/reservid",
        name: "reserves",
        component: ReservesWrapper,
        redirect: { name: "reserve-jobs" },
        children: [
            {
                path: "tool",
                name: "reserve-jobs",
                component: ReserveJobsView,
            },
            {
                path: "ootel-juhid",
                name: "pending-drivers",
                component: ReservePendingDriversView,
            },
            {
                path: "soidukijuhid",
                name: "reserve-drivers",
                component: ReserveDriversView,
            },
            {
                path: "soidukid",
                name: "reserve-vehicles",
                component: ReserveVehiclesView,
            },
        ],
    },
    {
        path: "/soidukorraldused",
        name: "job-orders",
        component: JobOrdersView,
    },
    {
        path: "/moodikud",
        name: "metrics",
        component: MetricsWrapper,
        redirect: { name: "metrics-dashboard" },
        children: [
            {
                path: "ulevaade",
                name: "metrics-dashboard",
                component: MetricsDashboardView,
            },
            {
                path: "juhtumid",
                name: "metrics-incidents",
                component: IncidentMetricsView,
            },
            {
                path: "tegemata-veootsad",
                name: "metrics-unserved-trips",
                component: UnservedTripMetricsView,
            },
            {
                path: "joudmine-ja-valjumine",
                name: "metrics-delays",
                component: DelayMetricsView,
            },
            {
                path: "reservid",
                name: "metrics-reserves",
                component: ReserveMetricsView,
            },
            {
                path: "tervisekontrollid",
                name: "metrics-health-inspections",
                component: HealthInspectionMetricsView,
            },
            {
                path: "planeeritud-kilomeetrid",
                name: "metrics-planned-and-served-km",
                component: PlannedAndServedKmMetricsView,
            },
        ],
    },
    {
        // This route is also defined in appsettings.json - PublicDocumentsConfiguration.
        // If this URL changes, changes must be carried to appsettings.json as well.
        path: "/avalikud-dokumendid/redirect/:vehicleId",
        name: "vehicle-redirect",
        component: VehicleRedirectionView,
    },
    {
        path: "/avalikud-dokumendid",
        name: "public-documents",
        component: PublicDocumentsView,
        meta: {
            hideMainNavbar: true,
        },
        children: [
            {
                path: ":jobId/tookorraldus/:driverId",
                name: "public-work-order",
                component: PublicWorkOrder,
            },
            {
                path: ":jobId/toograafik/:driverId",
                name: "public-work-schedule",
                component: PublicWorkSchedule,
            },
            {
                path: ":jobId/liinigraafik",
                name: "public-line-schedule",
                component: PublicLineSchedule,
            },
            {
                path: ":jobId/failid",
                name: "public-files",
                component: PublicFiles,
            },
            {
                path: ":jobId/teated/:driverId",
                name: "job-notes-and-delays",
                component: DelaysAndNotes,
            },
        ],
    },
    {
        path: "/juhtumid/",
        name: "incidents",
        component: IncidentsView,
    },
    {
        path: "/juhtumid/:id?",
        name: "incident",
        component: IncidentView,
    },
    {
        path: "/peakasutaja/",
        name: "admin",
        component: AdminView,
        redirect: { name: "users" },
        children: [
            {
                path: "kasutajad",
                name: "users",
                component: UsersView,
            },
            {
                path: "teenusekontod",
                name: "service-accounts",
                component: ServiceAccountsView,
            },
            {
                path: "paaslad",
                name: "parking-lots",
                component: ParkingLotsView,
            },
            {
                path: "tabloode-haldus",
                name: "service-table-management",
                component: AccountDisplayManagementView,
            },
            {
                path: "susteemi-parameetrid",
                name: "system-parameters",
                component: SystemParametersView,
            },
            {
                path: "klassifikaatorid",
                name: "classificators",
                component: ClassificatorsView,
            },
            {
                path: "teavituste-haldus",
                name: "notification-groups",
                component: NotificationGroupsView,
            },
            {
                path: "kontaktide-haldus",
                name: "phone-number-contacts",
                component: PhoneNumberContactsView,
            },
        ],
    },
    {
        path: "/seire",
        name: "monitoring",
        component: JobsMonitoringView,
        redirect: { name: "in-progress-jobs" },
        children: [
            {
                path: "kestvad-tood",
                name: "in-progress-jobs",
                component: InProgressJobMonitoring,
            },
            {
                path: "loppenud-tood",
                name: "ended-jobs",
                component: EndedJobsMonitoring,
            },
            {
                path: "valjumiste-tabloo",
                name: "departures-table",
                component: DeparturesMonitoringTable,
            },
        ],
    },
    {
        path: "/stiiliraamat/",
        name: "style-guide",
        component: StyleGuideView,
    },
    {
        path: "/valjumiste-tabloo",
        name: "departures-table-view",
        component: DeparturesTableView,
    },
    {
        path: "/liinigraafikud",
        name: "line-schedules",
        component: LineSchedulePrinting,
        meta: {
            hideMainNavbar: true,
        },
    },
    {
        path: "/teated",
        name: "job-delays",
        component: JobDelays,
    },
    {
        path: "/monitooring",
        name: "external-services-monitoring-view",
        component: ExternalServicesMonitoringView,
        redirect: { name: "update-times" },
        children: [
            {
                path: "uuendused",
                name: "update-times",
                component: UpdateTimes,
            },
            {
                path: "katkestused",
                name: "interruptions",
                component: Interruptions,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    removeModalElements();

    if (userInfo.roleType == api.RoleType.ServiceAccount && to.name === "server-error") {
        setTimeout(() => {
            window.location.href = userInfo.defaultView;
        }, 10000); // 10 seconds
    }
    next();
});

const removeModalElements = () => {
    const backdrop = document.querySelector(".modal-backdrop");
    document.body.classList.remove("modal-open");
    document.body.style.paddingRight = "unset";
    document.body.style.overflow = "unset";

    if (backdrop) {
        backdrop.remove();
    }
};

const groupParamsByKey = (params: URLSearchParams) =>
    [...params.entries()].reduce((acc: any, tuple) => {
        // getting the key and value from each tuple
        const [key, val] = tuple;
        if (Object.prototype.hasOwnProperty.call(acc, key)) {
            // if the current key is already an array, we'll add the value to it
            if (Array.isArray(acc[key])) {
                acc[key] = [...acc[key], val];
            } else {
                // if it's not an array, but contains a value, we'll convert it into an array
                // and add the current value to it
                acc[key] = [acc[key], val];
            }
        } else {
            // plain assignment if no special case is present
            acc[key] = val;
        }

        return acc;
    }, {});

export default router;
