import { api } from "@/api-client";

class DocumentTypeTranslator {
    translateFromDocumentType(type: api.DocumentType) {
        switch (type) {
            case api.DocumentType.Other:
                return "Muu";
            case api.DocumentType.Reroute:
                return "Ümbersõit";
        }
    }

    // @ts-expect-error
    translateFromString(type: string): string {
        switch (type) {
            case String(api.DocumentType[api.DocumentType.Other]):
                return "Muu";
            case String(api.DocumentType[api.DocumentType.Reroute]):
                return "Ümbersõit";
        }
    }
}

const documentTypeTranslator = new DocumentTypeTranslator();
export default documentTypeTranslator;
