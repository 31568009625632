import { api } from "@/api-client";
import moment, { FromTo } from "moment-timezone";

export const METRIC_MIN_DATE = moment("2024-01-01").toDate();
export const DAY_METRIC_MAX_DAYS = 90;

export function getAdjustedDateRange(timeUnit: api.MetricTimeUnit, dateRange: FromTo): FromTo {
    if (timeUnit === api.MetricTimeUnit.Days) {
        const daysPassed = moment(dateRange.to, "DD.MM.YYYY").diff(moment(dateRange.from, "DD.MM.YYYY"), "days");
        const adjustedFrom = daysPassed > DAY_METRIC_MAX_DAYS ? moment(dateRange.to, "DD.MM.YYYY").add(-DAY_METRIC_MAX_DAYS, "days").startOf("day").toDate() : dateRange.from;

        return {
            from: adjustedFrom,
            to: dateRange.to,
        };
    }

    return dateRange;
}

export function getPercentClassBindings(value: number | undefined) {
    const THRESHOLD = 0.005;

    return {
        "text-success": value != null && value >= THRESHOLD,
        "text-danger": value != null && value <= -THRESHOLD,
        "text-muted": value == null || (value < THRESHOLD && value > -THRESHOLD),
    };
}

export function getPercentageChange(currentValue: number, previousValue: number | undefined): number | undefined {
    if (previousValue == null) {
        return undefined;
    }

    if (previousValue === 0) {
        return currentValue == 0 ? 0 : 1; // e.g. if the current value is >0 and the previous value is 0, return "+100%"
    }

    return (currentValue - previousValue) / previousValue;
}

export function formatPercent(value: number | undefined, decimals = 0) {
    if (value == null) {
        return "";
    }
    const roundedValue = Math.round(value * 100 * Math.pow(10, decimals)) / Math.pow(10, decimals);

    return `${roundedValue}%`;
}

export function formatPercentWithSign(value: number | undefined) {
    if (value == null) {
        return "";
    }

    const roundedValue = Math.round(value * 100);
    return roundedValue > 0 ? `+${roundedValue}%` : `${roundedValue}%`;
}

export function formatSecondsAsMinutesAndSeconds(seconds: number, withSign = false): string {
    const time = moment.duration(seconds, "seconds");
    const sign = time.asMinutes() === 0 ? "" : time.asMinutes() > 0 ? "+" : "-";

    return `${withSign ? sign : ""}${Math.floor(Math.abs(time.asMinutes()))}:${Math.abs(time.seconds()).toString().padStart(2, "0")}`;
}
