import script from "./RedirectionForm.vue?vue&type=script&setup=true&lang=ts"
export * from "./RedirectionForm.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QField from 'quasar/src/components/field/QField.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QItem,QItemSection,QField,QRadio});
