import { api } from "@/api-client";
import moment from "moment-timezone";

interface IIncidentFormModel {
    selectedIncidentTypeGroup: number | null;
    selectedIncidentType: number | null;

    selectedLocationType: number | null;
    locationDescription: string;

    incidentDateTime: Date | null;
    description: string;
    wasPersonInjured: boolean;

    point: api.Point | null;
    closestStopOrParkingLotName: string | null;

    kmToNextStop: number | null;

    notifierType: "tltWorker" | "thirdParty";
    notifierName: string;
    notifierPhone: string;
    selectedNotifyingDriver: number | null;
}

export class IncidentModel implements IIncidentFormModel {
    selectedIncidentTypeGroup: number | null;
    selectedIncidentType: number | null;

    selectedLocationType: number | null;
    locationDescription: string;

    incidentDateTime: Date | null;
    description: string;
    wasPersonInjured: boolean;

    point: api.Point | null;
    closestStopOrParkingLotName: string | null;

    kmToNextStop: number | null;

    notifierType: "tltWorker" | "thirdParty";
    notifierName: string;
    notifierPhone: string;
    selectedNotifyingDriver: number | null;

    constructor(data: IIncidentFormModel) {
        this.selectedIncidentTypeGroup = data.selectedIncidentTypeGroup;
        this.selectedIncidentType = data.selectedIncidentType;
        this.selectedLocationType = data.selectedLocationType;
        this.locationDescription = data.locationDescription;
        this.incidentDateTime = data.incidentDateTime;
        this.description = data.description;
        this.wasPersonInjured = data.wasPersonInjured;

        this.point = data.point;
        this.closestStopOrParkingLotName = data.closestStopOrParkingLotName;

        this.kmToNextStop = data.kmToNextStop;
        this.notifierType = data.notifierType;
        this.notifierName = data.notifierName;
        this.notifierPhone = data.notifierPhone;
        this.selectedNotifyingDriver = data.selectedNotifyingDriver;
    }

    clone() {
        return new IncidentModel({ ...this });
    }

    toInputModel(incident: api.IncidentViewModel | null, jobId: number | null, driverAndVehicleId: number | null, drivers: api.DriverViewModel[], includeLocationType: boolean) {
        const hasAnyCoordinateValues = this.point?.coordinates.length != 0;

        const selectedNotifyingDriver = drivers.find((d) => d.driverId === this.selectedNotifyingDriver) ?? null;

        const unchangedModel = incident == null ? null : IncidentModel.toInputModelFromIncident(incident);

        return new api.UpsertIncidentInputModel({
            descriptions: unchangedModel?.descriptions ?? [new api.DescriptionInputModel({ text: this.description })],
            // @ts-expect-error
            vehicles: jobId === null ? unchangedModel?.vehicles : null,
            // @ts-expect-error
            drivers: jobId === null ? unchangedModel?.drivers : null,
            incidentId: incident?.id,
            jobId: jobId,
            driverAndVehicleId: driverAndVehicleId,
            incidentTime: this.incidentDateTime,
            typeGroupId: this.selectedIncidentTypeGroup,
            typeId: this.selectedIncidentType,
            wasPersonInjured: this.wasPersonInjured,
            location: new api.LocationInputModel({
                typeId: includeLocationType ? this.selectedLocationType : null,
                kmToNextStop: this.kmToNextStop,
                description: this.locationDescription,
                point: !hasAnyCoordinateValues ? null : this.point,
                closestStopOrParkingLotName: this.closestStopOrParkingLotName,
            }),
            notifier: new api.UpsertIncidentNotifierModel(
                this.notifierType === "tltWorker"
                    ? { driverId: selectedNotifyingDriver?.driverId }
                    : { name: this.notifierName === "" ? null : this.notifierName, phone: this.notifierPhone === "" ? null : this.notifierPhone }
            ),
        });
    }

    static fromApiModel(apiModel: api.IncidentViewModel, drivers: api.DriverViewModel[]): IncidentModel {
        const selectedNotifyingDriver = drivers.find((driver) => driver.driverId === apiModel.notifier?.driverId) ?? null;

        const data: IIncidentFormModel = {
            selectedIncidentTypeGroup: apiModel.type.groupType.id,
            selectedIncidentType: apiModel.type.id,
            // @ts-expect-error
            selectedLocationType: apiModel.location.type?.id,
            // @ts-expect-error
            locationDescription: apiModel.location.description,
            incidentDateTime: apiModel.incidentTime,
            description: "", // can't edit description in form after creation
            // @ts-expect-error
            wasPersonInjured: apiModel.wasPersonInjured,
            point: apiModel.location.point ?? new api.Point(),
            // @ts-expect-error
            closestStopOrParkingLotName: apiModel.location.closestStopOrParkingLotName,
            // @ts-expect-error
            kmToNextStop: apiModel.location.kmToNextStop,
            notifierType: apiModel.notifier.driverId === null ? "thirdParty" : "tltWorker",
            // @ts-expect-error
            notifierName: apiModel.notifier.driverId === null ? apiModel.notifier.name : null,
            // @ts-expect-error
            notifierPhone: apiModel.notifier.driverId === null ? apiModel.notifier.phone : null,
            // @ts-expect-error
            selectedNotifyingDriver: selectedNotifyingDriver?.driverId,
        };

        return new IncidentModel(data);
    }

    static toInputModelFromIncident(incident: api.IncidentViewModel) {
        const hasCoordinateValues = incident.location?.point?.coordinates.length != 0;

        return new api.UpsertIncidentInputModel({
            descriptions: incident.descriptions.map((d) => new api.DescriptionInputModel({ descriptionId: d.descriptionId, text: d.text })),
            vehicles: incident.vehicles.map((v) => v.vehicleId),
            drivers: incident.drivers.map((d) => d.driverId),
            incidentId: incident.id,
            incidentTime: moment(incident.incidentTime).toDate(),
            typeGroupId: incident.type.groupType.id,
            typeId: incident.type.id,
            wasPersonInjured: incident.wasPersonInjured,
            location: new api.LocationInputModel({
                typeId: incident.location.type?.id,
                kmToNextStop: incident.location.kmToNextStop,
                description: incident.location.description,
                point: hasCoordinateValues ? incident.location.point : null,
                closestStopOrParkingLotName: incident.location.closestStopOrParkingLotName,
            }),
            notifier: new api.UpsertIncidentNotifierModel({
                driverId: incident.notifier.driverId,
                name: incident.notifier.driverId === null ? incident.notifier.name : null,
                phone: incident.notifier.driverId === null ? incident.notifier.phone : null,
            }),
        });
    }

    static translateIncidentStatusFlag(status: api.IncidentStatusFlag) {
        switch (status) {
            case api.IncidentStatusFlag.InProgress:
                return "Töös";
            case api.IncidentStatusFlag.Completed:
                return "Lõpetatud";
            case api.IncidentStatusFlag.Confirmed:
                return "Kinnitatud";
            case api.IncidentStatusFlag.AutomaticallyConfirmed:
                return "Automaatselt kinnitatud";
            case api.IncidentStatusFlag.Deleted:
                return "Kustutatud";
            default:
                throw Error("Unknown incident status");
        }
    }
}

export const getDefaultModel = () =>
    new IncidentModel({
        selectedIncidentTypeGroup: null,
        selectedIncidentType: null,

        selectedLocationType: null,
        locationDescription: "",

        incidentDateTime: moment().toDate(),
        description: "",
        wasPersonInjured: false,

        point: new api.Point(),
        closestStopOrParkingLotName: "",
        kmToNextStop: null,

        notifierType: "tltWorker",
        notifierName: "",
        notifierPhone: "",
        selectedNotifyingDriver: null,
    });
