import { api } from "@/api-client";
import mobisInstanceTranslator from "@/features/MobisInstanceTranslator";

abstract class UnservedTripMetricFilter {
    abstract label: string;
    abstract options: string[];
    selectedOptions: string[] = [];

    removeOption(removedOption: string) {
        this.selectedOptions = this.selectedOptions.filter((x) => x !== removedOption);
    }

    clear() {
        this.selectedOptions = [];
    }
}

class UnservedTripMobisInstanceFilter extends UnservedTripMetricFilter {
    label = "Sõidukiliik";
    mobisInstances: { mobisInstance: api.MobisInstance; label: string }[] = [];
    options: string[] = [];

    constructor() {
        super();
        this.mobisInstances = Object.keys(api.MobisInstance)
            .filter((x) => isNaN(Number(x)))
            .map((x) => ({ label: mobisInstanceTranslator.translateStringToVehicleType(x), mobisInstance: mobisInstanceTranslator.translateStringToEnumValue(x) }));
        this.options = this.mobisInstances.map((x) => x.label);
    }

    toInputModel() {
        return this.selectedOptions.map((x) => this.mobisInstances.find((y) => y.label === x)?.mobisInstance).filter((x) => x != null);
    }
}

class UnservedTripTypeGroupFilter extends UnservedTripMetricFilter {
    label = "Liik";
    options: string[] = [];

    incidentTypeGroups: api.IIncidentTypeGroupViewModel[];

    constructor(incidentTypeGroups: api.IIncidentTypeGroupViewModel[]) {
        super();
        this.incidentTypeGroups = incidentTypeGroups;
        this.options = incidentTypeGroups.map((tg) => tg.name);
    }

    toInputModel() {
        const selected = this.incidentTypeGroups.filter((tg) => this.selectedOptions.includes(tg.name));
        return selected.map((x) => x.id);
    }
}

class UnservedTripTypeFilter extends UnservedTripMetricFilter {
    label = "Alamliik";
    options: string[] = [];

    incidentTypes: api.IIncidentTypeViewModel[];

    constructor(incidentTypeGroups: api.IIncidentTypeGroupViewModel[]) {
        super();
        this.incidentTypes = incidentTypeGroups.flatMap((tg) => tg.incidentTypes);
        this.options = this.incidentTypes.map((t) => t.name).filter((name) => name !== "Muu juhtum");
    }

    toInputModel() {
        const selected = this.incidentTypes.filter((t) => this.selectedOptions.includes(t.name));
        return selected.map((x) => x.id);
    }
}

class UnservedTripIncidentRegistrarFilter extends UnservedTripMetricFilter {
    label = "Registreerija";
    options: string[] = [];

    incidentRegistrars: api.IIncidentRegistrarViewModel[];

    constructor(incidentRegistrars: api.IIncidentRegistrarViewModel[]) {
        super();
        this.incidentRegistrars = incidentRegistrars;
        this.options = incidentRegistrars.map((ir) => ir.name);
    }

    toInputModel() {
        const selected = this.incidentRegistrars.filter((ir) => this.selectedOptions.includes(ir.name));
        return selected.map((x) => x.id);
    }
}

type UnservedTripFilter = {
    mobisInstances?: UnservedTripMobisInstanceFilter;
    incidentTypeGroups?: UnservedTripTypeGroupFilter;
    incidentTypes?: UnservedTripTypeFilter;
    registrars?: UnservedTripIncidentRegistrarFilter;
};

export class UnservedTripListFilters {
    filters: UnservedTripFilter = {};
    incidentTypeGroups?: api.IIncidentTypeGroupViewModel[];
    registrars?: api.IIncidentRegistrarViewModel[];

    getFilters() {
        return Object.values(this.filters);
    }

    constructor() {
        this.resetFilters();
    }

    resetFilters() {
        this.filters = {
            mobisInstances: new UnservedTripMobisInstanceFilter(),
            incidentTypeGroups: new UnservedTripTypeGroupFilter(this.incidentTypeGroups ?? []),
            incidentTypes: new UnservedTripTypeFilter(this.incidentTypeGroups ?? []),
            registrars: new UnservedTripIncidentRegistrarFilter(this.registrars ?? []),
        };
    }

    async init() {
        const incidentsClient = new api.IncidentsClient();
        this.incidentTypeGroups = await incidentsClient.incidentTypes();

        const metricsClient = new api.MetricsClient();
        this.registrars = await metricsClient.incidentRegistrars();

        this.resetFilters();
    }
}
