import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";
import { api } from "@/api-client";

export class UpdateTimeChangedMessage extends HubMessage<UpdateTimeChangedMessage> {
    type!: api.ExternalServiceOperationName;
    time!: Date;

    public override overrideFromJSON(data: any): UpdateTimeChangedMessage {
        const result = new UpdateTimeChangedMessage();
        result.type = data["type"];
        result.time = new Date(data["time"].toString());
        return result;
    }
}

export type OnUpdateTimeChanged = (message: UpdateTimeChangedMessage) => void;

export class UpdateTimesHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("update-times-hub");
    }

    public async open(onChange: OnUpdateTimeChanged) {
        await super.onMessage(UpdateTimeChangedMessage, "Upsert", onChange);
        await super.start();

        return this;
    }

    public async close() {
        await super.stop();
    }
}
