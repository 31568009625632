import { JobsMonitoringCommonFilters } from "@/features/jobsMonitoring/JobsMonitoringCommonFilters";
import { api } from "@/api-client";
import { formatTime } from "@/helpers/DateFormatter";
import router from "@/router";

export class EndedJobsTableFilters extends JobsMonitoringCommonFilters {
    expectedGarageArrivalTimeFrom: string | undefined;
    expectedGarageArrivalTimeTo: string | undefined;

    createData() {
        const data = {
            ...super.createData(),
            expectedGarageArrivalTimeFrom: this.expectedGarageArrivalTimeFrom ? this.expectedGarageArrivalTimeFrom : undefined,
            expectedGarageArrivalTimeTo: this.expectedGarageArrivalTimeTo ? this.expectedGarageArrivalTimeTo : undefined,
        };

        return data;
    }

    clearAllFilters() {
        super.clearAllFilters();
        this.expectedGarageArrivalTimeFrom = undefined;
        this.expectedGarageArrivalTimeTo = undefined;
    }

    applyOn(viewModels: api.MonitoredJobViewModel[]): api.MonitoredJobViewModel[] {
        return viewModels
            .filter((vm) => super.applyCommonFiltersOn(vm))
            .filter((vm) => (this.expectedGarageArrivalTimeFrom && vm.job.expectedGarageArrivalTime ? formatTime(vm.job.expectedGarageArrivalTime) >= this.expectedGarageArrivalTimeFrom : true))
            .filter((vm) => (this.expectedGarageArrivalTimeTo && vm.job.expectedGarageArrivalTime ? formatTime(vm.job.expectedGarageArrivalTime) < this.expectedGarageArrivalTimeTo : true));
    }

    saveToSessionStorage() {
        sessionStorage.setItem("EndedJobsTableFilters", JSON.stringify(this.createData()));
    }

    map() {
        const sessionStorageData = sessionStorage.getItem("EndedJobsTableFilters");
        if (sessionStorageData) {
            const data = JSON.parse(sessionStorageData);
            super.mapFromObject(data);
            this.expectedGarageArrivalTimeFrom = data.expectedGarageArrivalTimeFrom;
            this.expectedGarageArrivalTimeTo = data.expectedGarageArrivalTimeTo;
        } else {
            const route = router.currentRoute.value;

            this.expectedGarageArrivalTimeFrom = route.query.expectedGarageArrivalTimeFrom ? (route.query.expectedGarageArrivalTimeFrom as string) : undefined;
            this.expectedGarageArrivalTimeTo = route.query.expectedGarageArrivalTimeTo ? (route.query.expectedGarageArrivalTimeTo as string) : undefined;

            super.mapFromRoute(route);
        }
    }

    static clearSessionStorage() {
        sessionStorage.removeItem("EndedJobsTableFilters");
    }
}
