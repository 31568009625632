import { api } from "@/api-client";

class MobisInstanceTranslator {
    translateToVehicleCategory(instance: api.MobisInstance) {
        return {
            [api.MobisInstance.Bus]: api.VehicleCategory.Bus,
            [api.MobisInstance.BusSpecialTransport]: api.VehicleCategory.Bus,
            [api.MobisInstance.Tram]: api.VehicleCategory.Tram,
            [api.MobisInstance.Trolleybus]: api.VehicleCategory.Trolleybus,
        }[instance];
    }

    translateToVehicleType(instance: api.MobisInstance) {
        switch (instance) {
            case api.MobisInstance.Bus:
                return "Buss";
            case api.MobisInstance.Tram:
                return "Tramm";
            case api.MobisInstance.Trolleybus:
                return "Troll";
            case api.MobisInstance.BusSpecialTransport:
                return "Erivedu";
        }
    }

    translateStringToVehicleType(instance: string): string {
        // @ts-expect-error
        return this.translateToVehicleType(api.MobisInstance[instance]);
    }

    translateStringToEnumValue(instance: string) {
        switch (instance) {
            case String(api.MobisInstance[api.MobisInstance.Bus]):
                return api.MobisInstance.Bus;
            case String(api.MobisInstance[api.MobisInstance.Tram]):
                return api.MobisInstance.Tram;
            case String(api.MobisInstance[api.MobisInstance.Trolleybus]):
                return api.MobisInstance.Trolleybus;
            case String(api.MobisInstance[api.MobisInstance.BusSpecialTransport]):
                return api.MobisInstance.BusSpecialTransport;
            default:
                throw new Error("Unknown instance type");
        }
    }
}

const mobisInstanceTranslator = new MobisInstanceTranslator();
export default mobisInstanceTranslator;
