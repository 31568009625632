import { api } from "@/api-client";
import mobisInstanceTranslator from "@/features/MobisInstanceTranslator";

abstract class PlannedAndServedKmMetricFilter {
    abstract label: string;
    abstract options: string[];
    selectedOptions: string[] = [];

    removeOption(removedOption: string) {
        this.selectedOptions = this.selectedOptions.filter((x) => x !== removedOption);
    }

    clear() {
        this.selectedOptions = [];
    }
}

class PlannedAndServedKmMobisInstanceFilter extends PlannedAndServedKmMetricFilter {
    label = "Sõidukiliik";
    mobisInstances: { mobisInstance: api.MobisInstance; label: string }[] = [];
    options: string[] = [];

    constructor() {
        super();
        const validMobisInstances = [api.MobisInstance.Bus, api.MobisInstance.Tram, api.MobisInstance.Trolleybus];
        this.mobisInstances = validMobisInstances.map((mobisInstance) => ({
            label: mobisInstanceTranslator.translateToVehicleType(mobisInstance),
            mobisInstance,
        }));
        this.options = this.mobisInstances.map((x) => x.label);
    }

    toInputModel() {
        return this.selectedOptions.map((x) => this.mobisInstances.find((y) => y.label === x)?.mobisInstance).filter((x) => x != null);
    }
}

export class PlannedAndServedKmListFilters {
    mobisInstances = new PlannedAndServedKmMobisInstanceFilter();

    getFilters() {
        return [this.mobisInstances];
    }

    resetFilters() {
        this.mobisInstances.clear();
    }
}
