import { JobsMonitoringCommonFilters } from "@/features/jobsMonitoring/JobsMonitoringCommonFilters";
import { IMonitoredJobAndVehicleLocationSnapshotModel } from "@/features/jobsMonitoring/MonitoredJobAndVehicleLocationSnapshotModel";
import router from "@/router";

export class InProgressTableFilters extends JobsMonitoringCommonFilters {
    deviationInMinutes?: number;
    deviationFilterType?: "lesserThan" | "greaterThan";
    healthInspectionNotPassed: "true" | "false" | undefined = undefined;

    createData() {
        const data = {
            ...super.createData(),
            deviation: this.deviationInMinutes ? this.deviationInMinutes : undefined,
            deviationFilterType: this.deviationFilterType ? this.deviationFilterType : undefined,
            healthInspectionNotPassed: this.healthInspectionNotPassed ? this.healthInspectionNotPassed : undefined,
        };

        return data;
    }

    clearAllFilters() {
        super.clearAllFilters();
        this.deviationInMinutes = undefined;
        this.deviationFilterType = undefined;
        this.healthInspectionNotPassed = undefined;
    }

    applyOn(viewModels: IMonitoredJobAndVehicleLocationSnapshotModel[]): IMonitoredJobAndVehicleLocationSnapshotModel[] {
        if (viewModels == null) return [];

        return viewModels
            .filter((vm) => super.applyCommonFiltersOn(vm.monitoredJob))
            .filter((vm) =>
                this.healthInspectionNotPassed === "true"
                    ? vm.monitoredJob.healthInspection == null || !vm.monitoredJob.healthInspection.hasPositiveTestResult
                    : this.healthInspectionNotPassed === "false"
                    ? vm.monitoredJob.healthInspection != null && vm.monitoredJob.healthInspection.hasPositiveTestResult
                    : true
            )
            .filter(
                (vm) =>
                    this.deviationInMinutes == undefined ||
                    this.deviationFilterType == undefined ||
                    (this.deviationFilterType == "lesserThan"
                        ? // @ts-expect-error
                          vm.vehicleLocationSnapshot?.deviation?.deviationInMinutes < this.deviationInMinutes
                        : this.deviationFilterType == "greaterThan"
                        ? // @ts-expect-error
                          vm.vehicleLocationSnapshot?.deviation?.deviationInMinutes > this.deviationInMinutes
                        : true)
            )
            .filter((vm) => (this.locations.length ? this.locations.includes(vm?.vehicleLocationSnapshot?.currentLocation ?? JobsMonitoringCommonFilters.NO_LOCATION) : true));
    }

    saveToSessionStorage() {
        sessionStorage.setItem("InProgressTableFilters", JSON.stringify(this.createData()));
    }

    map() {
        const route = router.currentRoute.value;
        const hasRoute = Object.keys(route.query).length > 0;
        const sessionStorageData = sessionStorage.getItem("InProgressTableFilters");

        if (hasRoute) {
            this.deviationInMinutes = route.query.deviation ? Number(route.query.deviation) : undefined;
            this.deviationFilterType = route.query.deviationFilterType ? (route.query.deviationFilterType as "lesserThan" | "greaterThan") : undefined;
            this.healthInspectionNotPassed = route.query.healthInspectionNotPassed ? (route.query.healthInspectionNotPassed as "true" | "false") : undefined;

            super.mapFromRoute(route);
        } else if (sessionStorageData) {
            const data = JSON.parse(sessionStorageData);
            super.mapFromObject(data);

            this.deviationInMinutes = data.deviationInMinutes;
            this.healthInspectionNotPassed = data.healthInspectionNotPassed;
            this.deviationFilterType = data.deviationFilterType;
        }
    }

    static clearSessionStorage() {
        sessionStorage.removeItem("InProgressTableFilters");
    }
}
