import hubController from "@/components/signalR/HubController";
import { App, Ref, ref, Plugin, InjectionKey, inject } from "vue";
import userInfo from "@/components/UserInfo";
import { ExternalServiceInterruptionsHub, OnExternalServiceInterruptionsNotification } from "@/features/externalServicesMonitoring/interruptions/ExternalServiceInterruptionsHub";
import { IAppPlugin } from "@/plugins/AppPlugins";
import { PubliclyInaccessibleVehiclesNotifier } from "@/plugins/PubliclyInaccessibleVehiclesNotifier";

export class ExternalServiceInterruptionsNotifier implements IAppPlugin {
    private _externalServicesMonitoringHubHandler: ExternalServiceInterruptionsHub | null = null;

    public static readonly MESSAGE_KEY: InjectionKey<Ref<boolean>> = Symbol("EXTERNAL_SERVICE_INTERRUPTIONS_NOTIFIER_MESSAGE_KEY");
    public static readonly INSTANCE_KEY: InjectionKey<ExternalServiceInterruptionsNotifier> = Symbol("EXTERNAL_SERVICE_INTERRUPTIONS_NOTIFIER_KEY");

    private _onNotify: OnExternalServiceInterruptionsNotification = (message) => {
        this._notification.value = message.showNotification;
    };

    private _notification = ref<boolean>(false);

    install = (app: App, options: unknown) => {
        if (userInfo.isAuthenticated && userInfo.canConnectToExternalServicesHub) {
            hubController.startExternalServicesMonitoringHub(this._onNotify).then((handler) => (this._externalServicesMonitoringHubHandler = handler));
        }

        app.provide(ExternalServiceInterruptionsNotifier.MESSAGE_KEY, this._notification);
        app.provide(ExternalServiceInterruptionsNotifier.INSTANCE_KEY, this);
    };

    public reloadIfNeeded = async () => {
        if (this._externalServicesMonitoringHubHandler == null || this._externalServicesMonitoringHubHandler.isClosed()) {
            await hubController.startExternalServicesMonitoringHub(this._onNotify);
        }
    };
}

export default new ExternalServiceInterruptionsNotifier().install as Plugin;
