import script from "./DatetimePicker.vue?vue&type=script&lang=ts&setup=true"
export * from "./DatetimePicker.vue?vue&type=script&lang=ts&setup=true"

import "./DatetimePicker.vue?vue&type=style&index=0&id=ca64c9c0&lang=scss"

const __exports__ = script;

export default __exports__
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QField});
