import script from "./ServiceAccountsTable.vue?vue&type=script&setup=true&lang=ts"
export * from "./ServiceAccountsTable.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QChip,QTable,QTd});
