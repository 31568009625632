import script from "./FormTab.vue?vue&type=script&lang=ts&setup=true"
export * from "./FormTab.vue?vue&type=script&lang=ts&setup=true"

import "./FormTab.vue?vue&type=style&index=0&id=0c69dc28&lang=scss"

const __exports__ = script;

export default __exports__
import QTab from 'quasar/src/components/tabs/QTab.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTab,QIcon});
