import script from "./JobDelayCard.vue?vue&type=script&lang=ts&setup=true"
export * from "./JobDelayCard.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCheckbox,QIcon});
