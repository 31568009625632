import { api } from "@/api-client";

function ensure<T>(value: T | undefined | null): T {
    if (value === undefined || value === null) {
        throw new TypeError("Required value not found.");
    }

    return value;
}

export type DriverAndVehicle = ReturnType<typeof getJobWithDriversAndVehicles>["driversAndVehicles"][number];

export function getJobWithDriversAndVehicles(job: api.IIncidentJobModel, incident: api.IIncidentViewModel) {
    const incidentJob = incident.jobs.find((incidentJob) => incidentJob.jobId === job.jobId);
    const incidentDriverIds = incident.drivers.map((driver) => driver.driverId);
    const incidentVehicleIds = incident.vehicles.map((vehicle) => vehicle.vehicleId).filter((id) => id !== null);

    const driversAndVehicles = job.servingDriversAndVehicles
        .filter((dv) => incidentDriverIds.includes(dv.driver.driverId) && (dv.vehicle == null || incidentVehicleIds.includes(dv.vehicle.id)))
        .map((dv) => ({
            id: dv.id,
            driver: ensure(incident.drivers.find((driver) => driver.driverId === dv.driver.driverId)),
            vehicle: incident.vehicles.find((vehicle) => dv.vehicle != null && vehicle.vehicleId === dv.vehicle.id),
        }));

    return {
        job: { ...job, isReadOnly: incidentJob?.isReadOnly ?? false },
        driversAndVehicles: driversAndVehicles,
    };
}

export function getJobWithReadOnlyTags(job: api.IJobListItem, incident: api.IIncidentViewModel) {
    const incidentJob = incident.jobs.find((incidentJob) => incidentJob.jobId === job.jobId);

    return {
        ...job,
        isReadOnly: incidentJob?.isReadOnly ?? false,
        servingDriversAndVehicles: job.servingDriversAndVehicles.map((dv) => ({
            ...dv,
            driver: { ...dv.driver, isReadOnly: incident.drivers.find((driver) => driver.driverId === dv.driver.driverId)?.isReadOnly ?? false },
            vehicle: dv.vehicle != null ? { ...dv.vehicle, isReadOnly: incident.vehicles.find((vehicle) => vehicle.vehicleId === dv.vehicle?.id)?.isReadOnly ?? false } : undefined,
        })),
    };
}

export function createRelatedResourcesInputModelFromIncident(incident: api.IIncidentViewModel): api.IUpdateIncidentRelatedResourcesInputModel {
    return {
        jobIds: incident.jobs.map((job) => job.jobId),
        driverIds: incident.drivers.map((driver) => driver.driverId),
        vehicleIds: incident.vehicles.map((vehicle) => vehicle.vehicleId).filter((id) => id !== null),
    };
}
