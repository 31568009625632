import { type RouteLocationNormalized } from "vue-router";
import { ListFilters } from "@/components/ListFilters";
import { api } from "@/api-client";
import Router from "@/router";
import moment from "moment-timezone";

export abstract class DeparturesListFilters extends ListFilters {
    searchString: string | undefined = undefined;
    vehicleTypes: string[] = [];
    stopNames: string[] = [];
    plannedDepartureFrom: string | undefined;
    plannedDepartureTo: string | undefined;
    driverDepartment: string[] = [];

    abstract STORAGE_KEY: string;
    static readonly NO_VEHICLE = "Sõiduk puudub";

    map(route: RouteLocationNormalized) {
        const hasRoute = Object.keys(route.query).length > 0;
        const stored = sessionStorage.getItem(this.STORAGE_KEY);

        if (hasRoute) {
            this.vehicleTypes = route.query.vehicleTypes ? String(route.query.vehicleTypes).split(",") : [];
            this.searchString = route.query.search ? String(route.query.search) : undefined;
            this.stopNames = route.query.stopNames ? String(route.query.stopNames).split(",") : [];
            this.plannedDepartureFrom = route.query.plannedDepartureFrom ? (route.query.plannedDepartureFrom as string) : undefined;
            this.driverDepartment = route.query.driverDepartment ? String(route.query.driverDepartment).split(",") : [];
            this.plannedDepartureTo = route.query.plannedDepartureTo ? (route.query.plannedDepartureTo as string) : undefined;
        } else if (stored) {
            const storedFilters = JSON.parse(stored);
            this.vehicleTypes = storedFilters.vehicleTypes ?? [];
            this.searchString = storedFilters.search ?? undefined;
            this.stopNames = storedFilters.stopNames ?? [];
            this.plannedDepartureFrom = storedFilters.plannedDepartureFrom ?? undefined;
            this.driverDepartment = storedFilters.driverDepartment ?? [];
            this.plannedDepartureTo = storedFilters.plannedDepartureTo ?? undefined;
        }
    }

    createQuery() {
        return {
            search: this.searchString?.length ? this.searchString : undefined,
            vehicleTypes: this.vehicleTypes.length ? this.vehicleTypes : undefined,
            stopNames: this.stopNames.length ? this.stopNames : undefined,
            plannedDepartureFrom: this.plannedDepartureFrom ? this.plannedDepartureFrom : undefined,
            plannedDepartureTo: this.plannedDepartureTo ? this.plannedDepartureTo : undefined,
            driverDepartment: this.driverDepartment.length ? this.driverDepartment : undefined,
        };
    }

    saveToSessionStorage() {
        sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.createQuery()));
    }

    async reflectToRoute(router: typeof Router) {
        await router.replace({ query: this.createQuery() });
    }

    clearAllFilters() {
        sessionStorage.removeItem(this.STORAGE_KEY);
        this.vehicleTypes = [];
        this.stopNames = [];
        this.plannedDepartureFrom = undefined;
        this.plannedDepartureTo = undefined;
        this.driverDepartment = [];
    }

    applyOn(departures: api.IDepartureViewModel[]): api.IDepartureViewModel[] {
        departures = this.filterByPlannedDeparture(departures);

        return departures
            .filter((departure) =>
                this.vehicleTypes.length
                    ? this.vehicleTypes.includes(
                          departure.servingDriverAndVehicle.vehicle == null || departure.servingDriverAndVehicle.vehicle?.type == null
                              ? DeparturesListFilters.NO_VEHICLE
                              : departure.servingDriverAndVehicle.vehicle.type.label()
                      )
                    : true
            )
            .filter((departure) => (this.stopNames.length ? this.stopNames.includes(departure.stop?.name ?? "") : true))
            .filter((departure) => (this.driverDepartment.length ? this.driverDepartment.includes(departure.servingDriverAndVehicle.driver.department ?? "") : true))
            .filter((departure) =>
                this.searchString?.length != null && this.searchString.length >= 1
                    ? departure.servingDriverAndVehicle.driver.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
                      departure.servingDriverAndVehicle.vehicle?.garageNumber?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                      departure.servingDriverAndVehicle.driver.tableNumber.toLowerCase().includes(this.searchString.toLowerCase())
                    : true
            );
    }

    filterByPlannedDeparture(departures: api.IDepartureViewModel[]) {
        const filterFrom = this.plannedDepartureFrom != null && moment(this.plannedDepartureFrom, "HH:mm").isValid() ? moment(this.plannedDepartureFrom, "HH:mm") : undefined;
        let filterTo = this.plannedDepartureTo != null && moment(this.plannedDepartureTo, "HH:mm").isValid() ? moment(this.plannedDepartureTo, "HH:mm") : undefined;

        if (filterFrom != null && filterTo != null && filterTo < filterFrom) {
            filterTo = filterTo.add(1, "day"); // tomorrow night
        }

        return departures
            .filter((departure) => (filterFrom != null ? moment(departure.plannedDeparture) >= filterFrom : true))
            .filter((job) => (filterTo != null ? moment(job.plannedDeparture) <= filterTo : true));
    }
}
