import { HubConnectionProvider, HubMessage } from "@/components/signalR/HubConnectionProvider";
import { api } from "@/api-client";

export class VehicleArrivalMessage extends HubMessage<VehicleArrivalMessage> {
    jobId!: number;
    driverId!: number;
    vehicleId?: number;
    actualArrival!: Date;
    plannedArrival!: Date;

    public override overrideFromJSON(data: any): VehicleArrivalMessage {
        const result = new VehicleArrivalMessage();
        result.jobId = data["jobId"];
        result.driverId = data["driverId"];
        result.vehicleId = data["vehicleId"];
        result.actualArrival = new Date(data["actualArrival"].toString());
        result.plannedArrival = new Date(data["plannedArrival"].toString());
        return result;
    }
}
export type OnVehicleArrived = (message: VehicleArrivalMessage) => void;

export class VehicleArrivalRemovedMessage extends HubMessage<VehicleArrivalRemovedMessage> {
    jobId!: number;
    driverId!: number;
    vehicleId?: number;
    plannedArrival!: Date;

    public override overrideFromJSON(data: any): VehicleArrivalRemovedMessage {
        const result = new VehicleArrivalRemovedMessage();
        result.jobId = data["jobId"];
        result.driverId = data["driverId"];
        result.vehicleId = data["vehicleId"];
        result.plannedArrival = new Date(data["plannedArrival"].toString());
        return result;
    }
}
export type OnVehicleArrivalRemoved = (message: VehicleArrivalRemovedMessage) => void;

export class NewEndedMonitoredJobAddedHubMessage extends HubMessage<NewEndedMonitoredJobAddedHubMessage> {
    monitoredJob!: api.MonitoredJobViewModel;

    public override overrideFromJSON(data: any): NewEndedMonitoredJobAddedHubMessage {
        const result = new NewEndedMonitoredJobAddedHubMessage();
        result.monitoredJob = api.MonitoredJobViewModel.fromJS(data["monitoredJob"]);
        return result;
    }
}
export type OnEndedJobAdded = (message: NewEndedMonitoredJobAddedHubMessage) => void;

export class VehicleArrivalsHub extends HubConnectionProvider {
    constructor() {
        super("returns-from-jobs-hub");
    }

    public async openVehicleArrived(onChange: OnVehicleArrived, onChangeRemoved: OnVehicleArrivalRemoved) {
        await super.onMessage(VehicleArrivalMessage, "VehicleArrived", onChange);
        await super.onMessage(VehicleArrivalRemovedMessage, "VehicleArrivalRemoved", onChangeRemoved);
        await super.start();
        return this;
    }

    public async openEndedJobAdded(onChange: OnEndedJobAdded) {
        await super.onMessage(NewEndedMonitoredJobAddedHubMessage, "NewEndedMonitoredJobAdded", onChange);
        await super.start();
        return this;
    }

    public async close() {
        await super.stop();
    }
}
