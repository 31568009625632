import { api } from "@/api-client";

abstract class HealthInspectionFilter {
    abstract label: string;
    abstract options: readonly string[];
    selectedOptions: string[] = [];

    removeOption(removedOption: string) {
        this.selectedOptions = this.selectedOptions.filter((x) => x !== removedOption);
    }

    clear() {
        this.selectedOptions = [];
    }
}

class HealthInspectionDepartmentFilter extends HealthInspectionFilter {
    label = "Osakond";
    departments: string[] = [];
    options: readonly string[] = [];

    constructor(departments: string[]) {
        super();
        this.options = [...departments].sort();
    }

    toInputModel() {
        return this.selectedOptions;
    }
}

type HealthInspectionListFilter = {
    departments?: HealthInspectionDepartmentFilter;
};

export class HealthInspectionListFilters {
    departments?: string[] = [];
    filters: HealthInspectionListFilter = {};

    constructor() {
        this.resetFilters();
    }

    getFilters() {
        return Object.values(this.filters);
    }

    resetFilters() {
        this.filters = {
            departments: new HealthInspectionDepartmentFilter(this.departments ?? []),
        };
    }

    async init() {
        const client = new api.MetricsClient();
        this.departments = (await client.driverDepartments()).departments;
        this.filters.departments = new HealthInspectionDepartmentFilter(this.departments);
    }
}
