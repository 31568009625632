import script from "./SetAccountViewModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./SetAccountViewModal.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QSelect});
