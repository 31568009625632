import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";
import { api } from "@/api-client";

export class HealthInspectionUpsertedMessage extends HubMessage<HealthInspectionUpsertedMessage> {
    driverId!: number;
    healthInspection!: api.HealthInspectionViewModel;

    public override overrideFromJSON(messageJson: any) {
        this.driverId = messageJson["driverId"];
        this.healthInspection = api.HealthInspectionViewModel.fromJS(messageJson["healthInspection"]);

        return this;
    }
}
export type OnHealthInspectionUpsert = (message: HealthInspectionUpsertedMessage) => void;

export class HealthInspectionRemovedMessage extends HubMessage<HealthInspectionRemovedMessage> {
    healthInspectionId!: number;

    public override overrideFromJSON(messageJson: any) {
        this.healthInspectionId = messageJson["healthInspectionId"];
        return this;
    }
}
export type OnHealthInspectionRemove = (message: HealthInspectionRemovedMessage) => void;

export class HealthInspectionHub extends HubConnectionProvider implements IHub {
    departures: api.IDepartureViewModel[] = [];

    constructor() {
        super("health-inspection-hub");
    }

    public async open(onUpsert: OnHealthInspectionUpsert, onRemove: OnHealthInspectionRemove) {
        await super.onMessage(HealthInspectionUpsertedMessage, "Upsert", onUpsert);
        await super.onMessage(HealthInspectionRemovedMessage, "Remove", onRemove);

        await super.start();

        return this;
    }

    public async close() {
        await super.stop();
    }
}
