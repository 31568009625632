import { RouteLocationNormalized } from "vue-router";
import Router from "@/router";
import { api } from "@/api-client";
import ReserveDriverViewModel = api.ReserveDriverViewModel;
import { ReserveWorkTypeFilter } from "@/features/reserves/ReserveWorkTypeFilter";

export class ReserveDriversListFilters extends ReserveWorkTypeFilter {
    searchString: string | undefined = undefined;
    departments: string[] = [];
    competencies: string[] = [];

    static readonly NO_COMPETENCIES = "Kompetentsid puuduvad";

    readonly STORAGE_KEY = "ReserveDriversListFilters";

    map(route: RouteLocationNormalized) {
        const hasRoute = Object.keys(route.query).length > 0;
        const storage = sessionStorage.getItem(this.STORAGE_KEY);

        if (hasRoute) {
            this.searchString = route.query.search ? String(route.query.search) : undefined;
            this.departments = route.query.departments ? String(route.query.departments).split(",") : [];
            this.competencies = route.query.competencies ? String(route.query.competencies).split(",") : [];
        } else if (storage) {
            const data = JSON.parse(storage);
            this.searchString = data.searchString ?? undefined;
            this.departments = data.departments ?? [];
            this.competencies = data.competencies ?? [];
        }

        super.map(route);
    }

    async persist(router: typeof Router) {
        const query = {
            ...super.createQuery(),
            search: this.searchString?.length ? this.searchString : undefined,
            departments: this.departments.length ? this.departments : undefined,
            competencies: this.competencies.length ? this.competencies : undefined,
        };

        sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(query));

        await router.replace({
            query: query,
        });
    }

    clearAllFilters() {
        this.searchString = undefined;
        this.departments = [];
        this.competencies = [];
        super.clearAllFilters();
    }

    applyOn(drivers: Array<ReserveDriverViewModel>): Array<ReserveDriverViewModel> {
        return (super.applyOn(drivers) as Array<ReserveDriverViewModel>)
            .filter((driver) => filterByDepartment(this.departments, driver))
            .filter((driver) => filterByCompetence(this.competencies, driver))
            .filter((driver) => filterBySearchString(this.searchString?.toLowerCase(), driver));

        function filterByDepartment(departments: string[], driver: ReserveDriverViewModel): boolean {
            if (!departments.length) {
                return true;
            }
            return driver.department != null && departments.includes(driver.department);
        }

        function filterByCompetence(competencies: string[], driver: ReserveDriverViewModel): boolean {
            if (!competencies.length) {
                return true;
            }
            const driverCompetencies = driver.competencies.length ? driver.competencies.map((c) => c.name) : [];

            return driverCompetencies.length ? driverCompetencies.some((c) => competencies.includes(c)) : competencies.includes(ReserveDriversListFilters.NO_COMPETENCIES);
        }

        function filterBySearchString(searchString: string | undefined, driver: ReserveDriverViewModel): boolean {
            if (!searchString?.length) {
                return true;
            }
            return driver.fullName.toLowerCase().includes(searchString) || driver.tableNumber.toLowerCase().includes(searchString);
        }
    }
}
