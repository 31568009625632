import hubController from "@/components/signalR/HubController";
import { App, Ref, ref, Plugin, InjectionKey, inject } from "vue";
import { api } from "@/api-client";
import { ExpiredUpdatesHub, OnUpdatesExpiredNotification } from "@/features/externalServicesMonitoring/updateTimes/ExpiredUpdatesHub";
import userInfo from "@/components/UserInfo";
import { PubliclyInaccessibleVehiclesNotifier } from "@/plugins/PubliclyInaccessibleVehiclesNotifier";

export class ExpiredUpdatesNotifier {
    public static readonly MESSAGE_KEY: InjectionKey<Ref<api.ExternalServiceOperationName[]>> = Symbol("EXPIRED_UPDATES_NOTIFIER_KEY");
    public static readonly INSTANCE_KEY: InjectionKey<ExpiredUpdatesNotifier> = Symbol("EXPIRED_UPDATES_NOTIFIER");

    private _expiredUpdatesHubHandler: ExpiredUpdatesHub | null = null;
    private _onNotify: OnUpdatesExpiredNotification = (message) => {
        this._expiredUpdateNames.value = message.updateNames;
    };

    private _expiredUpdateNames = ref<api.ExternalServiceOperationName[]>([]);

    install = (app: App, options: unknown) => {
        if (userInfo.isAuthenticated && userInfo.canConnectToExpiredUpdatesHub) {
            hubController.startExpiredUpdatesHub(this._onNotify).then((handler) => (this._expiredUpdatesHubHandler = handler));
        }

        app.provide(ExpiredUpdatesNotifier.MESSAGE_KEY, this._expiredUpdateNames);
        app.provide(ExpiredUpdatesNotifier.INSTANCE_KEY, this);
    };

    public async reloadIfNeeded() {
        if (this._expiredUpdatesHubHandler == null || this._expiredUpdatesHubHandler.isClosed()) {
            await hubController.startExpiredUpdatesHub(this._onNotify);
        }
    }
}

export default new ExpiredUpdatesNotifier().install as Plugin;
