import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";
import { api } from "@/api-client";

export class VehicleLocationSnapshotsUpdatedMessage extends HubMessage<VehicleLocationSnapshotsUpdatedMessage> {
    vehicleLocationSnapshots!: api.IVehicleLocationSnapshotViewModel[];

    public override overrideFromJSON(data: any): VehicleLocationSnapshotsUpdatedMessage {
        const result = new VehicleLocationSnapshotsUpdatedMessage();
        result.vehicleLocationSnapshots = data["vehicleLocationSnapshots"];
        return result;
    }
}
export type OnAllVehicleLocationSnapshotsUpdate = (message: VehicleLocationSnapshotsUpdatedMessage) => void;

export class AllVehicleLocationSnapshotsHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("vehicle-location-snapshots-hub");
    }

    public async open(onUpdate: OnAllVehicleLocationSnapshotsUpdate) {
        await super.onMessage(VehicleLocationSnapshotsUpdatedMessage, "Update", onUpdate);
        await super.start();
        return this;
    }

    public async close() {
        await super.stop();
    }
}
