import Toasts from "@/components/Toasts";
import { api } from "@/api-client";
import { nextTick } from "vue";

// Must be exposed by the using component in order for the v-validatable directive to access the errors.
export class ValidationErrorsHelper {
    validationErrors = new api.ResultError();

    constructor() {
        this.validationErrors.onValidationOccurred(() => {
            this.displayUnhandledMessages();
            Toasts.error("Midagi läks valesti. Paranda vead ja proovi uuesti.");
        });
    }

    displayUnhandledMessages() {
        nextTick(() => {
            for (const message of this.getUnhandledMessages()) {
                Toasts.error(message);
            }
        });
    }

    getValidationErrorsFor(propertyName: string) {
        const messageKey = Object.keys(this.validationErrors.messages).find((key) => key.toLowerCase() === propertyName);
        if (!messageKey) {
            return [];
        }
        return this.validationErrors.messages[messageKey];
    }

    getUnhandledMessages() {
        const unhandledKeys = Object.keys(this.validationErrors.messages).filter((k) => !this.validationErrors.handledMessages.some((hk) => k.toLowerCase() == hk.toLowerCase()));
        const unhandledMessages = [];

        for (const key of unhandledKeys) {
            for (const message of this.validationErrors.messages[key]) {
                unhandledMessages.push(message);
            }
        }

        return unhandledMessages;
    }

    clear() {
        this.validationErrors.clear();
    }
}
