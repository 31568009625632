import { api } from "@/api-client";
import mobisInstanceTranslator from "@/features/MobisInstanceTranslator";

export interface IScheduleListModel {
    jobId: number;
    lineAndOrder: string;
    workTime: string;
    vehicleType: string;
    driverName: string;
}

export class ScheduleListModel implements IScheduleListModel {
    jobId: number;
    lineAndOrder: string;
    workTime: string;
    vehicleType: string;
    driverName: string;

    constructor(lineScheduleViewModel: api.LineScheduleDocumentViewModel) {
        const hasRunNumber = lineScheduleViewModel.generalInfo.runNumber != null;

        this.jobId = lineScheduleViewModel.jobId;
        this.lineAndOrder = `${lineScheduleViewModel.lineNumbers.join(", ")} ${hasRunNumber ? "/ " + lineScheduleViewModel.generalInfo.runNumber : ""}`;
        this.workTime = lineScheduleViewModel.workTime;
        this.vehicleType = mobisInstanceTranslator.translateToVehicleType(lineScheduleViewModel.mobisInstance);
        this.driverName = lineScheduleViewModel.generalInfo.drivers.join(", ");
    }
}
