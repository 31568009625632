type IncidentType = { id: number; code: number };

export function findRelatedTypeGroupsByCode(incidentTypes: IncidentType[], code: number, currentType?: IncidentType): { before?: IncidentType; after?: IncidentType } {
    return (
        incidentTypes
            .filter((typeGroup) => typeGroup.id !== currentType?.id)
            // find codes that come closest after and before the given code in model.code
            .reduce<{ before?: IncidentType; after?: IncidentType }>((acc, typeGroup) => {
                const movingDown = currentType != null && currentType.code < code;

                if (typeGroup.code < code) {
                    acc.before = typeGroup;
                } else if (movingDown && typeGroup.code == code) {
                    acc.before = typeGroup;
                } else if (!movingDown && typeGroup.code == code) {
                    acc.after = typeGroup;
                } else if (acc.after == null && typeGroup.code > code) {
                    acc.after = typeGroup;
                }

                return acc;
            }, {})
    );
}
