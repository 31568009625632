import { HubConnectionProvider, HubMessage } from "@/components/signalR/HubConnectionProvider";
import { api } from "@/api-client";

export class UpsertJobDelayMessage extends HubMessage<UpsertJobDelayMessage> {
    delayInfo!: api.JobDelayWithInfoViewModel;

    public override overrideFromJSON(data: any): UpsertJobDelayMessage {
        const result = new UpsertJobDelayMessage();
        result.delayInfo = api.JobDelayWithInfoViewModel.fromJS(data["delayInfo"]);
        return result;
    }
}
export type OnJobDelayUpsert = (message: UpsertJobDelayMessage) => void;

export class JobDelaysHub extends HubConnectionProvider {
    constructor() {
        super("job-delays-hub");
    }

    public async open(onUpsert: OnJobDelayUpsert) {
        await super.onMessage(UpsertJobDelayMessage, "Upsert", onUpsert);

        await super.start();
        return this;
    }

    public async close() {
        await super.stop();
    }
}
