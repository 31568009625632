import Router from "@/router";
import { DeparturesListFilters } from "@/features/preparation/departures/DeparturesListFilters";

export class ReserveDeparturesListFilters extends DeparturesListFilters {
    STORAGE_KEY = "ReserveDeparturesListFilters";

    async reflectToRoute(router: typeof Router) {
        await router.replace({
            query: super.createQuery(),
        });
    }
}
