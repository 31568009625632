import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";

// Vehicle => accessible message
export class NotifyOfPubliclyAccessibleVehicleHubMessage extends HubMessage<NotifyOfPubliclyAccessibleVehicleHubMessage> {
    vehicleId!: number;

    public override overrideFromJSON(data: any): NotifyOfPubliclyAccessibleVehicleHubMessage {
        const result = new NotifyOfPubliclyAccessibleVehicleHubMessage();
        result.vehicleId = data["vehicleId"];
        return result;
    }
}

export type OnPubliclyAccessibleVehicleNotification = (message: NotifyOfPubliclyAccessibleVehicleHubMessage) => void;

// Vehicle => inaccessible message
export class NotifyOfPubliclyInaccessibleVehicleHubMessage extends HubMessage<NotifyOfPubliclyInaccessibleVehicleHubMessage> {
    vehicleId!: number;

    public override overrideFromJSON(data: any): NotifyOfPubliclyInaccessibleVehicleHubMessage {
        const result = new NotifyOfPubliclyInaccessibleVehicleHubMessage();
        result.vehicleId = data["vehicleId"];
        return result;
    }
}

export type OnPubliclyInaccessibleVehicleNotification = (message: NotifyOfPubliclyInaccessibleVehicleHubMessage) => void;

export class VehiclePublicAccessibilityChangedHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("vehicle-public-accessibility-changed-hub");
    }

    public async open(onPubliclyAccessibleVehicleNotification: OnPubliclyAccessibleVehicleNotification, onPubliclyInaccessibleVehicleNotification: OnPubliclyInaccessibleVehicleNotification) {
        await super.onMessage(NotifyOfPubliclyAccessibleVehicleHubMessage, "NotifyOfPubliclyAccessibleVehicle", onPubliclyAccessibleVehicleNotification);
        await super.onMessage(NotifyOfPubliclyInaccessibleVehicleHubMessage, "NotifyOfPubliclyInaccessibleVehicle", onPubliclyInaccessibleVehicleNotification);
        await super.start();
        return this;
    }

    public async close() {
        await super.stop();
    }
}
