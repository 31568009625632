import { api } from "@/api-client";
import { KeyOfType } from "@/helpers/Utils";
import moment, { FromTo } from "moment-timezone";
import { DelayListFilters } from "./DelayListFilters";

export const DELAY_RANGES: { key: KeyOfType<api.DelayMetrics, number>; label: string }[] = [
    { key: "zeroToFiveMinuteDelays", label: "Õigeaegne" },
    { key: "sixToTenMinuteDelays", label: "6-10 min" },
    { key: "elevenToEighteenMinuteDelays", label: "11-18 min" },
    { key: "overEighteenMinuteDelays", label: "> 18 min" },
];

export const DEFAULT_FILTERS: DelayMetricFiltersModelValue = {
    dateRange: {
        from: moment().startOf("year"),
        to: moment(),
    },
    filters: new DelayListFilters(),
    timeUnit: api.MetricTimeUnit.Months,
};

export type DelayMetricFiltersModelValue = {
    dateRange: FromTo;
    filters: DelayListFilters;
    timeUnit: api.MetricTimeUnit;
};
