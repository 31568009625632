import { api } from "@/api-client";

export class DriverSelectOptionModel implements quasar.QSelectOption<number> {
    label: string;
    value: number;
    competencies: api.CompetenceViewModel[];

    constructor(driver: api.DriverWithCompetenciesViewModel) {
        this.label = `${driver.tableNumber} (${driver.name})`;
        this.value = driver.driverId;
        this.competencies = driver.competencies;
    }
}
