import script from "./TextWithTooltip.vue?vue&type=script&lang=ts&setup=true"
export * from "./TextWithTooltip.vue?vue&type=script&lang=ts&setup=true"

import "./TextWithTooltip.vue?vue&type=style&index=0&id=0bdfa74e&lang=scss"

const __exports__ = script;

export default __exports__
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPopupProxy});
