import { LogLevel } from "@microsoft/signalr";

class Settings {
    private _apiBaseUrl = "";
    private _websocketMinimumLogLevel = LogLevel.Information;
    private _testEnvironmentTag: string | null = null;
    private _initialized = false;

    get apiBaseUrl() {
        return this._apiBaseUrl;
    }

    get websocketMinimumLogLevel() {
        return this._websocketMinimumLogLevel;
    }

    get testEnvironmentTag() {
        return this._testEnvironmentTag;
    }

    public init(apiBaseUrl: string, environmentTag: string | null, websocketMinimumLogLevel: LogLevel = LogLevel.Information) {
        if (this._initialized) {
            throw "Settings are already initialized";
        }
        this._apiBaseUrl = apiBaseUrl;
        this._websocketMinimumLogLevel = websocketMinimumLogLevel;
        this._testEnvironmentTag = environmentTag;
        this._initialized = true;
    }
}

export default new Settings();
