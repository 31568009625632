import moment from "moment-timezone";

export function utcDate(date: Date | undefined | null): Date | null | undefined {
    if (date instanceof Date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
    }

    return null;
}

export function formatDate(date: Date | moment.Moment | string | null | undefined, empty = "-"): string {
    if (date instanceof String && !moment.isMoment(date)) date = new Date(date);

    if (!date) return empty;

    if (!moment(date).isValid()) {
        return empty;
    }

    return moment(date).format("DD.MM.YYYY");
}

export function formatDateTime(date: Date | moment.Moment | string | null | undefined, empty = "-"): string {
    if (date instanceof String && !moment.isMoment(date)) date = new Date(date);

    if (!date) return empty;

    if (!moment(date).isValid()) {
        return empty;
    }

    return moment(date).format("DD.MM.YYYY HH:mm");
}

export function formatTime(date: Date | moment.Moment | string | null | undefined, empty = "-"): string {
    if (typeof date === "string" && !moment.isMoment(date)) {
        date = moment(date, "HH:mm");
    }

    if (!date) return empty;

    if (!moment(date).isValid()) {
        return empty;
    }

    return moment(date).format("HH:mm");
}
