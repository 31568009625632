import { api } from "@/api-client";
import { KeyOfType } from "@/helpers/Utils";
import moment from "moment-timezone";
import { PlannedAndServedKmListFilters } from "./PlannedAndServedKmListFilters";

export const DEFAULT_FILTERS = {
    dateRange: {
        from: moment().startOf("year"),
        to: moment(),
    },
    timeUnit: api.MetricTimeUnit.Months,
    filters: new PlannedAndServedKmListFilters(),
};

export type PlannedAndServedKmMetric = api.IPlannedAndServedKmMetricsViewModel & {
    label: string;
    kmDifferenceWithPassengers: number;
    kmDifferenceWithoutPassengers: number;
    servedRatioWithPassengers: number;
    servedRatioWithoutPassengers: number;
};

export type GraphFields = { key: KeyOfType<PlannedAndServedKmMetric, number>; label: string; graphType: "bar" | "percentLine" };
type TableFields = { key: KeyOfType<PlannedAndServedKmMetric, number>; label: string; highlighted?: boolean };

const WITH_PASSENGERS_FIELDS: (GraphFields & TableFields)[] = [
    { key: "plannedKmWithPassengers", label: "Planeeritud liini-kilomeetrid", graphType: "bar" },
    { key: "servedKmWithPassengers", label: "Läbitud liini-kilomeetrid", graphType: "bar" },
];
const WITHOUT_PASSENGERS_FIELDS: (GraphFields & TableFields)[] = [
    { key: "plannedKmWithoutPassengers", label: "Planeeritud tühisõidu-kilomeetrid", graphType: "bar" },
    { key: "servedKmWithoutPassengers", label: "Läbitud tühisõidu-kilomeetrid", graphType: "bar" },
];

export const WITH_PASSENGERS_GRAPH_FIELDS: GraphFields[] = [...WITH_PASSENGERS_FIELDS, { key: "servedRatioWithPassengers", label: "Läbitud %", graphType: "percentLine" }];
export const WITHOUT_PASSENGERS_GRAPH_FIELDS: GraphFields[] = [...WITHOUT_PASSENGERS_FIELDS, { key: "servedRatioWithoutPassengers", label: "Läbitud %", graphType: "percentLine" }];

export const TABLE_FIELDS: TableFields[] = [
    ...WITH_PASSENGERS_FIELDS,
    { key: "kmDifferenceWithPassengers", label: "Liini-kilomeetrite vahe", highlighted: true },
    ...WITHOUT_PASSENGERS_FIELDS,
    { key: "kmDifferenceWithoutPassengers", label: "Tühisõidu-kilomeetrite vahe", highlighted: true },
];
