import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel, RetryContext } from "@microsoft/signalr";
import settings from "@/settings";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { api } from "@/api-client";

export interface IHub {
    close: () => Promise<void>;
}

export abstract class HubMessage<T> {
    public abstract overrideFromJSON(messageJson: any): T;
}

export class HubConnectionProvider {
    private readonly connection: HubConnection;
    private readonly _logger: ConnectionLogger;

    protected constructor(hubEndPoint: string) {
        this._logger = new ConnectionLogger(this.constructor.name, settings.websocketMinimumLogLevel);
        this.connection = new HubConnectionBuilder()
            .withUrl(`${settings.apiBaseUrl}/websockets/${hubEndPoint}`, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
                withCredentials: false,
            })
            .withAutomaticReconnect({ nextRetryDelayInMilliseconds: this.calculateReconnectRetryDelay })
            .configureLogging(this._logger)
            .build();
    }

    private calculateReconnectRetryDelay(retryContext: RetryContext): number | null {
        const retryCount = retryContext.previousRetryCount;

        if (retryCount == 0) return 0;
        if (retryCount == 1) return 2_000; // 2 sec
        if (retryCount < 5) return 5_000; // 5 sec
        if (retryCount < 10) return 30_000; // 30 sec
        if (retryCount < 30) return 60_000; // 1 min
        if (retryCount < 60) return 30 * 60_000; // 30 min

        return null; // stop trying to reconnect
    }

    protected async start() {
        await this.connection.start().catch(function (err) {
            return console.error(err.toString());
        });
    }

    protected async stop() {
        await this.connection?.stop().catch(function (err) {
            return console.error(err.toString());
        });
    }

    protected async onMessage<T extends HubMessage<T>>(type: { new (): T }, methodName: string, callback: (message: T) => void) {
        this.connection?.off(methodName);
        this.connection?.on(methodName, async (messageJson: any) => callback(new type().overrideFromJSON(messageJson)));
    }

    protected async on(methodName: string, callback: () => void) {
        this.connection?.off(methodName);
        this.connection?.on(methodName, async () => callback());
    }

    // Resolves upon receiving a response from the server
    protected async invoke<T extends HubMessage<T>>(type: { new (): T }, methodName: string, message?: T | null) {
        // Passing null as a message/param results in a hub method not found if the hub method does not expect any parameters
        return message != null ? this.connection?.invoke(methodName, message) : this.connection?.invoke(methodName);
    }

    // Resolves upon sending a message to the server
    protected async send<T extends HubMessage<T>>(methodName: string, message?: T | null) {
        // Passing null as a message/param results in a hub method not found if the hub method does not expect any parameters
        return message != null ? this.connection?.send(methodName, message) : this.connection?.send(methodName);
    }

    public isClosed() {
        return this.connection?.state === "Disconnected"; // Note: Using HubConnectionState enum here causes runtime
        // exception on linux ?
    }

    protected isConnecting() {
        return this.connection?.state === "Connecting" || this.connection?.state === "Reconnecting";
    }
}

class ConnectionLogger extends ConsoleLogger {
    private readonly _context;
    private readonly _minimumLevel;

    constructor(context: string, minimumLogLevel: LogLevel) {
        super(minimumLogLevel);
        this._minimumLevel = minimumLogLevel;
        this._context = context;
    }

    log(logLevel: LogLevel, message: string) {
        if (logLevel >= this._minimumLevel) {
            const messageTemplate = `[${new Date().toLocaleTimeString()}] ${LogLevel[logLevel]}: (${this._context}) ${message}`;

            switch (logLevel) {
                case LogLevel.Critical:
                case LogLevel.Error:
                    this.out.error(messageTemplate);
                    break;
                case LogLevel.Warning:
                    this.out.warn(messageTemplate);
                    break;
                case LogLevel.Information:
                    this.out.info(messageTemplate);
                    break;
                case LogLevel.Trace:
                    this.out.log(messageTemplate);
                    break;
            }
        }
    }
}
