import { api } from "@/api-client";

export type JobOrderLocationModel = { kind: "stop"; location: api.StopViewModel | undefined | null } | { kind: "description"; location: string };

export function mapJobOrderToLocationModel(locationStopId: number | null | undefined, locationName: string, stops: api.StopViewModel[]): JobOrderLocationModel {
    if (locationStopId == null) {
        return {
            kind: "description",
            location: locationName,
        };
    }

    return {
        kind: "stop",
        location: stops.find((stop) => stop.id === locationStopId),
    };
}
