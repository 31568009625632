import { ListFilters } from "@/components/ListFilters";
import { api } from "@/api-client";
import documentTypeTranslator from "@/features/DocumentTypeTranslator";
import mobisInstanceTranslator from "@/features/MobisInstanceTranslator";
import { RouteLocationNormalizedLoaded, Router } from "vue-router";

export class ReroutesTableFilters extends ListFilters {
    searchString: string | undefined = undefined;
    transportTypes: string[] = [];
    documentTypes: string[] = [];

    mapFromRoute(route: RouteLocationNormalizedLoaded) {
        this.transportTypes = route.query.transportTypes ? String(route.query.transportTypes).split(",") : [];
        this.documentTypes = route.query.documentTypes ? String(route.query.documentTypes).split(",") : [];
    }

    async reflectToRoute(router: Router) {
        await router.replace({
            query: {
                transportTypes: this.transportTypes.length ? this.transportTypes : undefined,
                documentTypes: this.documentTypes.length ? this.documentTypes : undefined,
            },
        });
    }

    clearAllFilters() {
        this.transportTypes = [];
        this.documentTypes = [];
        this.searchString = undefined;
    }

    applyOn(reroutes: Array<api.RerouteListViewModel>): Array<api.RerouteListViewModel> {
        return (
            reroutes
                .filter((reroute) => filterByTransportType(this.transportTypes, reroute))
                .filter((reroute) => filterByDocumentType(this.documentTypes, reroute))
                // @ts-expect-error
                .filter((reroute) => filterBySearchString(this.searchString, reroute))
        );

        function filterByTransportType(transportTypes: string[], reroute: api.RerouteListViewModel): boolean {
            if (!transportTypes.length) {
                return true;
            }
            return transportTypes.includes(mobisInstanceTranslator.translateToVehicleType(reroute.transportType));
        }

        function filterByDocumentType(documentTypes: string[], reroute: api.RerouteListViewModel): boolean {
            if (!documentTypes.length) {
                return true;
            }
            return documentTypes.includes(documentTypeTranslator.translateFromDocumentType(reroute.documentType));
        }

        function filterBySearchString(searchString: string, reroute: api.RerouteListViewModel): boolean {
            if (!searchString) {
                return true;
            }
            return reroute.fileName.toLowerCase().includes(searchString.toLowerCase()) || reroute.lineNumbers.some((ln) => ln.lineNumber.toLowerCase().includes(searchString.toLowerCase()));
        }
    }
}
