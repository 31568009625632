export const graphColorPalette = [
    "#5673CC",
    "#62B5CC",
    "#CC8437",
    "#49CC8F",
    "#CB868B",
    "#5D549A",
    "#D9E62E",
    "#CC5C5C",
    "#E6B650",
    "#5DAE99",
    "#9274CC",
    "#D1CE85",
    "#91c4c5",
    "#BD9F83",
    "#EF747E",
    "#87B457",
    "#E2BB91",
    "#7289ab",
    "#BDDA83",
    "#659d84",
    "#A0CBEA",
    "#B7AACF",
    "#AE5C8A",
    "#73c0de",
    "#6B9BD4",
    "#7EC392",
    "#51689b",
    "#EDA46C",
    "#77897D",
    "#91cc75",
    "#759aa0",
    "#ea7e53",
];
