import script from "./CreateLineInsertionModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./CreateLineInsertionModal.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QTabPanel});
