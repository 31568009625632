import { ListFilters } from "@/components/ListFilters";
import { RouteLocationNormalized } from "vue-router";
import Router from "@/router";
import { api } from "@/api-client";
import workTypeTranslator from "@/features/WorkTypeTranslator";
import HasReserveJobInfoViewModel = api.HasReserveJobInfoViewModel;

export abstract class ReserveWorkTypeFilter extends ListFilters {
    workTypes: string[] = [];

    static readonly NO_WORK_TYPE = "Töö puudub";

    abstract readonly STORAGE_KEY: string;

    map(route: RouteLocationNormalized) {
        const hasRoute = Object.keys(route.query).length > 0;
        const storage = sessionStorage.getItem(this.STORAGE_KEY);

        if (hasRoute) {
            this.workTypes = route.query.workTypes ? String(route.query.workTypes).split(",") : [];
        } else if (storage) {
            const data = JSON.parse(storage);
            this.workTypes = data.workTypes ?? [];
        }
    }

    createQuery() {
        return {
            workTypes: this.workTypes.length ? this.workTypes : undefined,
        };
    }

    async persist(router: typeof Router) {
        sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.createQuery()));

        await router.replace({ query: this.createQuery() });
    }

    clearAllFilters() {
        sessionStorage.removeItem(this.STORAGE_KEY);

        this.workTypes = [];
    }

    applyOn(arr: Array<HasReserveJobInfoViewModel>): Array<HasReserveJobInfoViewModel> {
        return arr.filter((model) => filterByWorkType(this.workTypes, model));

        function filterByWorkType(workTypes: string[], model: HasReserveJobInfoViewModel): boolean {
            if (!workTypes.length) {
                return true;
            }
            return model.jobs.length ? model.jobs.some((j) => workTypes.includes(workTypeTranslator.translate(j.workType))) : workTypes.includes(ReserveWorkTypeFilter.NO_WORK_TYPE);
        }
    }
}
