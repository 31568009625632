export function getKilometersMismatchWarningMessage(kilometers: number | null | undefined, start: Date | null | undefined, end: Date | null | undefined): string | null {
    if (kilometers == null || start == null || end == null) {
        return null;
    }

    const durationInMinutes = Math.abs(end.getTime() - start.getTime()) / 1000 / 60;
    const expectedKm = durationInMinutes / 2; // Formula for expected kilometers - 1km = 2min

    // Allow 1km difference between expected and actual kilometers
    if (Math.abs(expectedKm - kilometers) > 1) {
        return `Sisestatud kilomeetrid ei kattu ajavahemikuga (1km = 2min). Veendu, et sisestasid korrektsed andmed.`;
    }

    return null;
}
