import { api } from "@/api-client";
import { hasValue } from "@/helpers/Utils";
import { ListFilters } from "@/components/ListFilters";
import vehicleCategoryTranslator from "@/features/VehicleCategoryTranslator";

export class VehiclesListFilters extends ListFilters {
    searchString: string | undefined = undefined;
    vehicleTypes: string[] = [];
    divisions: string[] = [];
    marks: string[] = [];

    static readonly NO_VEHICLE = "Sõiduk puudub";
    static readonly NO_DIVISION = "Osakond puudub";
    static readonly NO_MARK = "Mark puudub";

    clearAllFilters() {
        this.searchString = undefined;
        this.vehicleTypes = [];
        this.divisions = [];
        this.marks = [];
    }

    applyOn(vehicles: api.IVehicleViewModel[]): api.IVehicleViewModel[] {
        return vehicles
            .filter((vehicle) => filterVehicleType(this.vehicleTypes, vehicle))
            .filter((vehicle) => filterByDivision(this.divisions, vehicle))
            .filter((vehicle) => filterByMark(this.marks, vehicle))
            .filter((vehicle) => filterBySearchString(this.searchString, vehicle));

        function filterVehicleType(vehicleTypes: string[], vehicle: api.IVehicleViewModel): boolean {
            if (!vehicleTypes.length) {
                return true;
            }

            if (hasValue(vehicle.type.type)) {
                return vehicleTypes.includes(`${vehicleCategoryTranslator.translate(vehicle.type.category)} (${vehicle.type.type})`);
            }

            return vehicleTypes.includes(vehicleCategoryTranslator.translate(vehicle.type.category)!);
        }

        function filterByDivision(divisions: string[], vehicle: api.IVehicleViewModel): boolean {
            if (!divisions.length) {
                return true;
            }
            return vehicle.division == null ? divisions.includes(VehiclesListFilters.NO_DIVISION) : divisions.includes(vehicle.division);
        }

        function filterByMark(marks: string[], vehicle: api.IVehicleViewModel): boolean {
            if (!marks.length) {
                return true;
            }

            return hasValue(vehicle.mark) ? marks.includes(vehicle.mark) : marks.includes(VehiclesListFilters.NO_MARK);
        }

        function filterBySearchString(searchString: string | undefined, vehicle: api.IVehicleViewModel): boolean {
            if (!searchString?.length) {
                return true;
            }
            return (
                (vehicle.garageNumber != null && vehicle.garageNumber.toLowerCase().includes(searchString.toLowerCase())) ||
                (vehicle.registrationNumber != null && vehicle.registrationNumber?.toLowerCase().includes(searchString.toLowerCase()))
            );
        }
    }
}
