import { RouteLocationNormalized } from "vue-router";
import Router from "@/router";
import workTypeTranslator from "@/features/WorkTypeTranslator";
import { api } from "@/api-client";
import { DeparturesListFilters } from "@/features/preparation/departures/DeparturesListFilters";
import RegularDepartureViewModel = api.RegularDepartureViewModel;

export class RegularDeparturesListFilters extends DeparturesListFilters {
    lineNumbers: string[] = [];
    workTypes: string[] = [];

    STORAGE_KEY = "RegularDeparturesListFilters";

    map(route: RouteLocationNormalized) {
        const hasRoute = Object.keys(route.query).length > 0;
        const stored = sessionStorage.getItem(this.STORAGE_KEY);

        if (hasRoute) {
            this.lineNumbers = route.query.lineNumbers ? String(route.query.lineNumbers).split(",") : [];
            this.workTypes = route.query.workTypes ? String(route.query.workTypes).split(",") : [];
        } else if (stored) {
            const storedFilters = JSON.parse(stored);
            this.lineNumbers = storedFilters.lineNumbers ?? [];
            this.workTypes = storedFilters.workTypes ?? [];
        }

        super.map(route);
    }

    createQuery() {
        return {
            ...super.createQuery(),
            lineNumbers: this.lineNumbers.length ? this.lineNumbers : undefined,
            workTypes: this.workTypes.length ? this.workTypes : undefined,
        };
    }

    saveToSessionStorage() {
        sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.createQuery()));
    }

    async reflectToRoute(router: typeof Router) {
        await router.replace({ query: this.createQuery() });
    }

    clearAllFilters() {
        this.lineNumbers = [];
        this.workTypes = [];
        super.clearAllFilters();
    }

    applyOn(departures: Array<RegularDepartureViewModel>): Array<RegularDepartureViewModel> {
        return (super.applyOn(departures) as Array<RegularDepartureViewModel>)
            .filter((departure) => (this.lineNumbers.length ? this.lineNumbers.some((lineNumber) => departure.lineNumbers.includes(lineNumber)) : true))
            .filter((departure) => (this.workTypes.length ? this.workTypes.includes(workTypeTranslator.translate(departure.servingDriverAndVehicle.workType)) : true));
    }
}
