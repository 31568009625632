import { type RouteLocationNormalized } from "vue-router";
import { ListFilters } from "@/components/ListFilters";

export class DeparturesTableViewFilters extends ListFilters {
    stops: string[] = [];

    mapFromRoute(route: RouteLocationNormalized) {
        this.stops = route.query.peatus ? String(route.query.peatus).split(",") : [];
    }

    createQuery() {
        return {
            // Query parameter names are in estonian to match the naming style on all routes.
            peatus: this.stops.length ? this.stops : undefined,
        };
    }

    clearAllFilters() {
        this.stops = [];
    }
}
