<template>
    <div class="page">
        <div class="container-xxl">
            <h1>404 - Lehte ei leitud</h1>
            <p class="fs-lg mt-2">Palun kontrolli sisestatud aadressi või võta ühendust süsteemi administraatoriga.</p>
            <router-link to="/" class="btn btn-primary btn-lg mt-4">Avalehele</router-link>
        </div>
    </div>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
@import "~@/styles/abstracts/componentBase";

.page {
    padding-top: 6.25rem;

    h1 {
        font-size: 2.5rem;
    }
}
</style>
