import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";

export class ServiceAccountViewUpdateMessage extends HubMessage<ServiceAccountViewUpdateMessage> {
    serviceAccountId!: number;
    url!: string;

    public override overrideFromJSON(data: any): ServiceAccountViewUpdateMessage {
        const result = new ServiceAccountViewUpdateMessage();
        result.serviceAccountId = data["serviceAccountId"];
        result.url = data["url"];
        return result;
    }
}
export type OnServiceAccountViewUpdate = (message: ServiceAccountViewUpdateMessage) => void;

export class ServiceAccountViewHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("service-accounts-hub");
    }

    public async open(onUpdate: OnServiceAccountViewUpdate, onApplicationRestarted: () => void) {
        await super.onMessage(ServiceAccountViewUpdateMessage, "UpdateServiceAccountView", onUpdate);
        await super.on("ApplicationRestarted", async () => {
            onApplicationRestarted();
        });
        await super.start();
        return this;
    }

    public async close() {
        await super.stop();
    }
}
