import script from "./IncidentTable.vue?vue&type=script&lang=ts&setup=true"
export * from "./IncidentTable.vue?vue&type=script&lang=ts&setup=true"

import "./IncidentTable.vue?vue&type=style&index=0&id=6cbefb83&lang=scss"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QTable,QTd});
