import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';;
import { quasarUserOptions } from "./quasar-user-options";
import "bootstrap";
import settings from "@/settings";
import { formatDate, formatDateTime, formatTime, utcDate } from "@/helpers/DateFormatter";
import { isNullOrEmpty, now, getNonEmpty, distinct, getRandomComponentId } from "@/helpers/Utils";
import registerDirectives from "@/directives/directives";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import userInfo from "@/components/UserInfo";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { api } from "@/api-client";
import hubController from "@/components/signalR/HubController";
import { ServiceAccountViewUpdateMessage } from "@/features/shared/signalR/ServiceAccountsHub";
import ExternalServiceInterruptionsNotifier from "@/plugins/ExternalServiceInterruptionsNotifier";
import ExpiredUpdatesNotifier from "@/plugins/ExpiredUpdatesNotifier";
import PubliclyUnavailableVehiclesNotifier from "@/plugins/PubliclyInaccessibleVehiclesNotifier";
import moment from "moment-timezone";
import AppPlugins from "@/plugins/AppPlugins";

import "./styles/styles.scss";

declare module "@vue/runtime-core" {
    export interface ComponentCustomProperties {
        formatDate: (date: Date | string | null | undefined, empty?: string) => string;
        formatDateTime: (date: Date | moment.Moment | string | null | undefined, empty?: string) => string;
        formatTime: (date: Date | string | null | undefined, empty?: string) => string;
        isNullOrEmpty: (value: string | null | undefined) => boolean;
        getNonEmpty: (value: string | number | null | undefined, defaultValue: string) => string;
        utcDate: (date: Date | null) => Date | null | undefined;
        now: () => Date;
        distinct: <T>(array: T[]) => T[];
        getRandomComponentId: () => string;
    }
}

// set default timezone to estonian, overriding local browser timezones
moment.tz.setDefault("Europe/Tallinn");

const appCustomProperties = {
    formatDate: formatDate,
    formatDateTime: formatDateTime,
    formatTime: formatTime,
    utcDate: utcDate,
    isNullOrEmpty: isNullOrEmpty,
    now: now,
    getNonEmpty: getNonEmpty,
    distinct: distinct,
    getRandomComponentId: getRandomComponentId,
} as any;

export { appCustomProperties };

new Promise<void>((resolve, reject) => {
    (async () => {
        const appSettings =
            process.env.NODE_ENV === "production"
                ? ((await fetch("/settings")
                      .then((response) => response.json())
                      .catch((e) => {
                          throw `Unable to fetch app settings. Error: ${e as string}`;
                      })) as {
                      ApiBaseUrl: string;
                      TestEnvironmentTag: string | null;
                      WebSocketMinimumLogLevel: number;
                  })
                : {
                      ApiBaseUrl: "http://localhost:5231",
                      TestEnvironmentTag: "🅳🅴🆅",
                      WebSocketMinimumLogLevel: 2, // 0 = Trace, 1 = Debug, 2 = Information, 3 = Warning, 4 = Error, 5 = Critical, 6 = None
                  };
        settings.init(appSettings.ApiBaseUrl, appSettings.TestEnvironmentTag, appSettings.WebSocketMinimumLogLevel);
        resolve();
    })();
})
    .then(async () => {
        const app = createApp({
            extends: App,
        });

        // UserInfo ought to be refreshed before Notifiers(plugins) are initialized
        await userInfo.refreshUserInfo();

        app.use(Quasar, quasarUserOptions); // Chore: replace Toast with Quasar Notify
        app.use(router);
        app.use(Toast);

        // Plugins for injecting global properties
        app.use(AppPlugins);

        app.component("VueDatePicker", VueDatePicker);
        registerDirectives(app);

        Object.keys(appCustomProperties).forEach((key) => (app.config.globalProperties[key] = appCustomProperties[key]));

        const errorHandler = async (error: unknown) => {
            const errorAsAny = error as any;
            if (errorAsAny.isHandled) {
                return;
            }

            if (errorAsAny.status === 401) {
                const query = !isNullOrEmpty(router.currentRoute.value.query?.returnUrl?.toString()) ? router.currentRoute.value.query : { returnUrl: router.currentRoute.value.fullPath };
                await router.push({ name: "login", query: query });
                return;
            }
            if (errorAsAny.status === 403) {
                await router.push({ name: "forbidden" });
                return;
            }
            if (errorAsAny.status === 404) {
                await router.push({ name: "not-found" });
                return;
            }
            if (errorAsAny.status === 500) {
                await router.push({ name: "server-error" });
                return;
            }

            throw error;
        };

        router.onError((error) => errorHandler(error));
        app.config.errorHandler = (err, vm, info) => errorHandler(err);
        window.onerror = (err) => errorHandler(err);

        // Remove obsolete service worker
        // This code block can be removed after 2024.05.01 when hopefully service worker has been unregistered from all visitors
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .getRegistrations()
                .then(function (registrations) {
                    for (const registration of registrations) {
                        registration.unregister();
                    }
                })
                .catch(function (err) {
                    console.log("Service Worker registration failed: ", err);
                });
        }

        app.mount("#app");
    })
    .then(async () => {
        // Ensure that service account view hub is started on every refresh. Otherwise this is started after successful login. Is closed on logout.
        if (userInfo.roleType === api.RoleType.ServiceAccount) {
            await hubController.startServiceAccountViewHub(userInfo.id!, onServiceAccountViewUpdate, onApplicationRestart);
        }

        function onServiceAccountViewUpdate(message: ServiceAccountViewUpdateMessage | null) {
            if (message != null && message.serviceAccountId === userInfo.id) {
                window.location.href = message.url;
            }
        }

        function onApplicationRestart() {
            window.location.reload();
        }
    });
