import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";

export class DriverArrivalToJobMessage extends HubMessage<DriverArrivalToJobMessage> {
    jobId!: number;
    driverId!: number;
    arrivalTime!: Date;
    plannedArrivalTime!: Date;

    public override overrideFromJSON(data: any): DriverArrivalToJobMessage {
        const result = new DriverArrivalToJobMessage();
        result.jobId = data["jobId"];
        result.driverId = data["driverId"];
        result.arrivalTime = new Date(data["arrivalTime"].toString());
        result.plannedArrivalTime = new Date(data["plannedArrivalTime"].toString());
        return result;
    }
}
export type OnDriverArrivedToJob = (message: DriverArrivalToJobMessage) => void;

export class DriverPlannedArrivalRemovedMessage extends HubMessage<DriverPlannedArrivalRemovedMessage> {
    jobId!: number;
    driverId!: number;
    arrivalTime!: Date;
    plannedArrivalTime!: Date;

    public override overrideFromJSON(data: any): DriverPlannedArrivalRemovedMessage {
        const result = new DriverPlannedArrivalRemovedMessage();
        result.jobId = data["jobId"];
        result.driverId = data["driverId"];
        result.plannedArrivalTime = new Date(data["plannedArrivalTime"].toString());
        return result;
    }
}
export type OnDriverJobPlannedArrivalRemovedMessage = (message: DriverPlannedArrivalRemovedMessage) => void;

export class DriverArrivalToJobsHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("driver-arrivals-to-preparation");
    }

    public async open(onDriverArrivedToJob: OnDriverArrivedToJob, onDriverJobPlannedArrivalRemoved: OnDriverJobPlannedArrivalRemovedMessage) {
        await super.onMessage(DriverArrivalToJobMessage, "driverArrivalToJob", onDriverArrivedToJob);
        await super.onMessage(DriverPlannedArrivalRemovedMessage, "driverPlannedArrivalRemoved", onDriverJobPlannedArrivalRemoved);
        await super.start();
        return this;
    }

    public async close() {
        await super.stop();
    }
}
