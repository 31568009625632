import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";

export class NotifyOfPubliclyInaccessibleVehiclesHubMessage extends HubMessage<NotifyOfPubliclyInaccessibleVehiclesHubMessage> {
    hasPubliclyInaccessibleVehicles = false;

    public override overrideFromJSON(data: any): NotifyOfPubliclyInaccessibleVehiclesHubMessage {
        const result = new NotifyOfPubliclyInaccessibleVehiclesHubMessage();
        result.hasPubliclyInaccessibleVehicles = data["hasPubliclyInaccessibleVehicles"];
        return result;
    }
}

export type OnPubliclyInaccessibleVehiclesNotification = (message: NotifyOfPubliclyInaccessibleVehiclesHubMessage) => void;

export class PubliclyInaccessibleVehiclesHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("publicly-inaccessible-vehicles-hub");
    }

    public async open(onNotifyOfPubliclyInaccessibleVehicles: OnPubliclyInaccessibleVehiclesNotification) {
        await super.onMessage(NotifyOfPubliclyInaccessibleVehiclesHubMessage, "NotifyOfPubliclyInaccessibleVehicles", onNotifyOfPubliclyInaccessibleVehicles);
        await super.start();
        return this;
    }

    public async close() {
        await super.stop();
    }

    public async getCurrentState() {
        await super.send("OnRequestCurrentState");
    }
}
