import { RouteLocationNormalized } from "vue-router";
import Router from "@/router";
import { api } from "@/api-client";
import ReserveVehicleViewModel = api.ReserveVehicleViewModel;
import { ReserveWorkTypeFilter } from "@/features/reserves/ReserveWorkTypeFilter";
import { hasValue } from "@/helpers/Utils";

export class ReserveVehiclesListFilters extends ReserveWorkTypeFilter {
    searchString: string | undefined = undefined;
    vehicleTypes: string[] = [];
    marks: string[] = [];
    models: string[] = [];
    divisions: string[] = [];
    technicalConditions: string[] = [];

    static readonly NO_VEHICLE = "Sõiduk puudub";
    static readonly NO_DIVISION = "Osakond puudub";
    static readonly NO_MARK = "Mark puudub";
    static readonly NO_MODEL = "Mudel puudub";

    readonly STORAGE_KEY = "ReserveVehiclesListFilters";

    map(route: RouteLocationNormalized) {
        const hasRoute = Object.keys(route.query).length > 0;
        const storage = sessionStorage.getItem(this.STORAGE_KEY);

        if (hasRoute) {
            this.searchString = route.query.search ? String(route.query.search) : undefined;
            this.vehicleTypes = route.query.vehicleTypes ? String(route.query.vehicleTypes).split(",") : [];
            this.marks = route.query.marks ? String(route.query.marks).split(",") : [];
            this.models = route.query.models ? String(route.query.models).split(",") : [];
            this.divisions = route.query.divisions ? String(route.query.divisions).split(",") : [];
            this.technicalConditions = route.query.technicalConditions ? String(route.query.technicalConditions).split(",") : [];
        } else if (storage) {
            const data = JSON.parse(storage);
            this.searchString = data.searchString ?? undefined;
            this.vehicleTypes = data.vehicleTypes ?? [];
            this.marks = data.marks ?? [];
            this.models = data.models ?? [];
            this.divisions = data.divisions ?? [];
            this.technicalConditions = data.technicalConditions ?? [];
        }

        super.map(route);
    }

    async persist(router: typeof Router) {
        const query = {
            ...super.createQuery(),
            search: this.searchString?.length ? this.searchString : undefined,
            vehicleTypes: this.vehicleTypes.length ? this.vehicleTypes : undefined,
            marks: this.marks.length ? this.marks : undefined,
            models: this.models.length ? this.models : undefined,
            divisions: this.divisions.length ? this.divisions : undefined,
            technicalConditions: this.technicalConditions.length ? this.technicalConditions : undefined,
        };

        sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(query));

        await router.replace({
            query: query,
        });
    }

    clearAllFilters() {
        this.searchString = undefined;
        this.vehicleTypes = [];
        this.marks = [];
        this.models = [];
        this.divisions = [];
        this.technicalConditions = [];
        super.clearAllFilters();
    }

    applyOn(vehicles: Array<ReserveVehicleViewModel>): Array<ReserveVehicleViewModel> {
        return (super.applyOn(vehicles) as Array<ReserveVehicleViewModel>)
            .filter((vehicle) => filterVehicleType(this.vehicleTypes, vehicle))
            .filter((vehicle) => filterByMark(this.marks, vehicle))
            .filter((vehicle) => filterByModel(this.models, vehicle))
            .filter((vehicle) => filterByDivision(this.divisions, vehicle))
            .filter((vehicle) => filterByTechnicalCondition(this.technicalConditions, vehicle))
            .filter((vehicle) => filterBySearchString(this.searchString, vehicle));

        function filterVehicleType(vehicleTypes: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!vehicleTypes.length) {
                return true;
            }
            return vehicleTypes.includes(vehicle?.type == null ? ReserveVehiclesListFilters.NO_VEHICLE : vehicle.type.label());
        }

        function filterByMark(marks: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!marks.length) {
                return true;
            }

            return hasValue(vehicle.mark) ? marks.includes(vehicle.mark) : marks.includes(ReserveVehiclesListFilters.NO_MARK);
        }

        function filterByModel(models: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!models.length) {
                return true;
            }

            return hasValue(vehicle.model) ? models.includes(vehicle.model) : models.includes(ReserveVehiclesListFilters.NO_MODEL);
        }

        function filterByDivision(divisions: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!divisions.length) {
                return true;
            }
            return vehicle.division == null ? divisions.includes(ReserveVehiclesListFilters.NO_DIVISION) : divisions.includes(vehicle.division);
        }

        function filterByTechnicalCondition(technicalConditions: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!technicalConditions.length) {
                return true;
            }
            return technicalConditions.includes(vehicle.repairStatus.technicalConditionLabel());
        }

        function filterBySearchString(searchString: string | undefined, vehicle: ReserveVehicleViewModel): boolean {
            if (!searchString?.length) {
                return true;
            }
            return (vehicle?.garageNumber?.toLowerCase().includes(searchString.toLowerCase()) || vehicle?.registrationNumber?.toLowerCase().includes(searchString.toLowerCase())) ?? false;
        }
    }
}
