<template>
    <div class="page">
        <div class="container-xxl">
            <h2>403</h2>
            <h1>Õigused puuduvad</h1>
            <div>Puuduvad vajalikud õigused juurdepääsuks. <br />Kui arvad, et tegu on eksitusega, võta ühendust süsteemi administraatoriga.</div>
            <router-link to="/" class="btn btn-primary btn-lg mt-4">Avalehele</router-link>
        </div>
    </div>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
@import "~@/styles/abstracts/componentBase";

.page {
    padding-top: 6.25rem;

    h1 {
        font-size: 2.5rem;
    }
}
</style>
