import { inject, ref, Ref } from "vue";
import { PubliclyInaccessibleVehiclesNotifier } from "@/plugins/PubliclyInaccessibleVehiclesNotifier";
import { api } from "@/api-client";
import { ExternalServiceInterruptionsNotifier } from "@/plugins/ExternalServiceInterruptionsNotifier";
import { ExpiredUpdatesNotifier } from "@/plugins/ExpiredUpdatesNotifier";

export class Injectables {
    // Publicly inaccessible vehicles
    public static usePubliclyInaccessibleVehiclesNotifications = function (): Ref<boolean> {
        return inject(PubliclyInaccessibleVehiclesNotifier.MESSAGE_KEY, ref<boolean>(false));
    };

    public static usePubliclyInaccessibleVehiclesNotifierInstance = function (): PubliclyInaccessibleVehiclesNotifier | undefined {
        return inject<PubliclyInaccessibleVehiclesNotifier>(PubliclyInaccessibleVehiclesNotifier.INSTANCE_KEY);
    };

    // External service interruptions
    public static useExternalServiceInterruptionsNotifications = function (): Ref<boolean> {
        return inject(ExternalServiceInterruptionsNotifier.MESSAGE_KEY, ref<boolean>(false));
    };

    public static useExternalServiceInterruptionsNotifierInstance = function (): ExternalServiceInterruptionsNotifier | undefined {
        return inject<ExternalServiceInterruptionsNotifier>(ExternalServiceInterruptionsNotifier.INSTANCE_KEY);
    };

    // Expired updates
    public static useExpiredUpdatesNotifications = function (): Ref<api.ExternalServiceOperationName[]> {
        return inject(ExpiredUpdatesNotifier.MESSAGE_KEY, ref<api.ExternalServiceOperationName[]>([]));
    };

    public static useExpiredUpdatesNotifierInstance = function (): ExpiredUpdatesNotifier | undefined {
        return inject<ExpiredUpdatesNotifier>(ExpiredUpdatesNotifier.INSTANCE_KEY);
    };
}
