import { RouteLocationNormalized } from "vue-router";
import Router from "@/router";
import { api } from "@/api-client";
import { ListFilters } from "@/components/ListFilters";
import moment, { FromTo } from "moment-timezone";

export class JobDelayFilters extends ListFilters {
    searchString: string | undefined = undefined;
    lineNumbers: string[] = [];
    vehicleTypes: string[] = [];
    driverDepartments: string[] = [];
    processingStatuses: string[] = [];
    dateRangeFilter: FromTo = { from: moment(), to: moment() };

    static readonly NO_VEHICLE = "Sõiduk puudub";

    public get dateRange() {
        // by default, moment.js converts the date to local timezone and rolls back to 21:00 the previous day, so let's prevent it
        return { from: moment.utc(this.dateRangeFilter.from, "DD.MM.YYYY"), to: moment.utc(this.dateRangeFilter.to, "DD.MM.YYYY") };
    }

    public set dateRange(value: { from: moment.Moment; to: moment.Moment }) {
        this.dateRangeFilter = { from: value.from, to: value.to };
    }

    mapFromRoute(route: RouteLocationNormalized) {
        this.searchString = route.query.search ? String(route.query.search) : undefined;
        this.lineNumbers = route.query.lineNumbers ? String(route.query.lineNumbers).split(",") : [];
        this.vehicleTypes = route.query.vehicleTypes ? String(route.query.vehicleTypes).split(",") : [];
        this.driverDepartments = route.query.driverTeams ? String(route.query.driverTeams).split(",") : [];
        this.processingStatuses = route.query.processingStatuses ? String(route.query.processingStatuses).split(",") : [];
        this.dateRangeFilter =
            route.query.dateFrom && route.query.dateTo
                ? { from: moment(String(route.query.dateFrom), "DD.MM.YYYY").utc(true), to: moment(String(route.query.dateTo), "DD.MM.YYYY").utc(true) }
                : { from: moment(), to: moment() };
    }

    createQuery() {
        return {
            search: this.searchString?.length ? this.searchString : undefined,
            lineNumbers: this.lineNumbers.length ? this.lineNumbers : undefined,
            vehicleTypes: this.vehicleTypes.length ? this.vehicleTypes : undefined,
            driverDepartments: this.driverDepartments.length ? this.driverDepartments : undefined,
            processingStatuses: this.processingStatuses.length ? this.processingStatuses : undefined,
            dateFrom: this.dateRangeFilter != null ? moment(this.dateRange.from).format("DD.MM.YYYY") : undefined,
            dateTo: this.dateRangeFilter != null ? moment(this.dateRange.to).format("DD.MM.YYYY") : undefined,
        };
    }

    async reflectToRoute(router: typeof Router) {
        await router.replace({ query: this.createQuery() });
    }

    clearAllFilters() {
        this.lineNumbers = [];
        this.vehicleTypes = [];
        this.driverDepartments = [];
        this.processingStatuses = [];
        this.dateRangeFilter = { from: moment(), to: moment() };
    }

    applyOn(jobs: api.JobDelayWithInfoViewModel[]): api.JobDelayWithInfoViewModel[] {
        return jobs
            .filter((job) => (this.lineNumbers.length ? this.lineNumbers.some((ln) => job.lineNumbers.includes(ln)) : true))
            .filter((job) =>
                this.vehicleTypes.length
                    ? (this.vehicleTypes.includes(JobDelayFilters.NO_VEHICLE) && job.vehicles.length == 0) || job.vehicles.some((v) => this.vehicleTypes.includes(v.vehicleType.label()))
                    : true
            )
            .filter((job) => (this.driverDepartments.length ? this.driverDepartments.includes(job.driverDepartment) : true))
            .filter((job) => (this.processingStatuses.length ? this.processingStatuses.includes(job.jobDelay.isProcessed ? "Töödeldud" : "Töötlemata") : true))
            .filter((job) => filterBySearchString(this.searchString, job));

        function filterBySearchString(searchString: string | undefined, job: api.JobDelayWithInfoViewModel): boolean {
            if (!searchString?.length) {
                return true;
            }
            const searchStringLower = searchString.toLowerCase();

            return (
                job.driverName.toLowerCase().includes(searchStringLower) ||
                job.driverTableNumber.toLowerCase().includes(searchStringLower) ||
                job.vehicles.some((v) => v.vehicleNumber?.toLowerCase().includes(searchStringLower))
            );
        }
    }
}
