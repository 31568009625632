import hubController from "@/components/signalR/HubController";
import { App, ref, Plugin } from "vue";
import userInfo from "@/components/UserInfo";
import { OnPubliclyInaccessibleVehiclesNotification, PubliclyInaccessibleVehiclesHub } from "@/features/shared/signalR/PubliclyInaccessibleVehiclesHub";
import { IAppPlugin } from "@/plugins/AppPlugins";

export class PubliclyInaccessibleVehiclesNotifier implements IAppPlugin {
    private _hubHandler: PubliclyInaccessibleVehiclesHub | null = null;
    private _onNotifyOfPubliclyInaccessibleVehicles: OnPubliclyInaccessibleVehiclesNotification = (message) => {
        this.showNotification.value = message.hasPubliclyInaccessibleVehicles;
    };

    private showNotification = ref<boolean>(false);

    install = (app: App, options: unknown) => {
        if (userInfo.isAuthenticated && userInfo.canConnectToPubliclyUnavailableVehiclesHub) {
            hubController.startPubliclyInaccessibleVehiclesHub(this._onNotifyOfPubliclyInaccessibleVehicles).then((handler) => (this._hubHandler = handler));
        }

        app.provide(PubliclyInaccessibleVehiclesNotifier.MESSAGE_KEY, this.showNotification);
        app.provide(PubliclyInaccessibleVehiclesNotifier.INSTANCE_KEY, this);
    };

    public reloadIfNeeded = async () => {
        if (this._hubHandler == null || this._hubHandler.isClosed()) {
            await hubController.startPubliclyInaccessibleVehiclesHub(this._onNotifyOfPubliclyInaccessibleVehicles);
        }
    };

    public static readonly MESSAGE_KEY: string = "PUBLICLY_INACCESSIBLE_VEHICLES_NOTIFIER_MESSAGE_KEY";
    public static readonly INSTANCE_KEY: string = "PUBLICLY_INACCESSIBLE_VEHICLES_NOTIFIER_KEY";
}

export default new PubliclyInaccessibleVehiclesNotifier().install as Plugin;
