import script from "./JobOrderLocationInput.vue?vue&type=script&lang=ts&setup=true"
export * from "./JobOrderLocationInput.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QRadio,QSelect,QInput});
