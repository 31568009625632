import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";
import { api } from "@/api-client";

export class VehicleLocationSnapshotUpdatedMessage extends HubMessage<VehicleLocationSnapshotUpdatedMessage> {
    vehicleLocationSnapshot!: api.IVehicleLocationSnapshotViewModel | null;

    public override overrideFromJSON(data: any): VehicleLocationSnapshotUpdatedMessage {
        const result = new VehicleLocationSnapshotUpdatedMessage();
        result.vehicleLocationSnapshot = api.VehicleLocationSnapshotViewModel.fromJS(data["vehicleLocationSnapshot"]);
        return result;
    }
}
export type OnSingleVehicleLocationSnapshotUpdate = (message: VehicleLocationSnapshotUpdatedMessage) => void;

export class SingleVehicleLocationSnapshotHub extends HubConnectionProvider implements IHub {
    constructor(vehicleId: number) {
        super(`vehicle-location-snapshots-hub/${vehicleId}`);
    }

    public async open(onUpdate: OnSingleVehicleLocationSnapshotUpdate) {
        await super.onMessage(VehicleLocationSnapshotUpdatedMessage, "Update", onUpdate);
        await super.start();
        return this;
    }

    public async close() {
        await super.stop();
    }
}
