import { ListFilters } from "@/components/ListFilters";
import { api } from "@/api-client";
import { RouteLocationNormalized } from "vue-router";
import Router from "@/router";
import mobisInstanceTranslator from "@/features/MobisInstanceTranslator";

export class VehicleTableFilters extends ListFilters {
    searchString: string | undefined = undefined;
    divisions: string[] = [];
    types: string[] = [];

    mapFromRoute(route: RouteLocationNormalized) {
        this.searchString = route.query.search ? String(route.query.search) : undefined;
        this.divisions = route.query.divisions ? String(route.query.divisions).split(",") : [];
        this.types = route.query.types ? String(route.query.types).split(",") : [];
    }

    async reflectToRoute(router: typeof Router) {
        await router.replace({ query: this.createQuery() });
    }

    private createQuery() {
        return {
            search: this.searchString?.length ? this.searchString : undefined,
            divisions: this.divisions.length ? this.divisions : undefined,
            types: this.types.length ? this.types : undefined,
        };
    }

    applyOn(vehicles: Array<api.VehicleAndRedirectUrlViewModel>): Array<api.VehicleAndRedirectUrlViewModel> {
        return vehicles
            .filter((vehicle) => filterBySearchString(this.searchString, vehicle.vehicle))
            .filter((vehicle) => filterByDivision(this.divisions, vehicle.vehicle))
            .filter((vehicle) => filterByType(this.types, vehicle.vehicle));

        function filterBySearchString(searchString: string | undefined, vehicle: api.VehicleViewModel): boolean {
            if (!searchString?.length) {
                return true;
            }

            const hasMatchingGarageNumber = vehicle.garageNumber != null && vehicle.garageNumber.toLowerCase().includes(searchString.toLowerCase());
            const hasMatchingRegistrationNumber = vehicle.registrationNumber != null && vehicle.registrationNumber.toLowerCase().includes(searchString.toLowerCase());
            const hasMatchingRoute = vehicle.vehicleRoute != null && vehicle.vehicleRoute.toLowerCase().includes(searchString.toLowerCase());

            return hasMatchingGarageNumber || hasMatchingRegistrationNumber || hasMatchingRoute;
        }

        function filterByDivision(divisions: string[], vehicle: api.VehicleViewModel): boolean {
            if (!divisions.length) {
                return true;
            }

            return vehicle.division != null && divisions.includes(vehicle.division);
        }

        function filterByType(types: string[], vehicle: api.VehicleViewModel): boolean {
            if (!types.length) {
                return true;
            }

            return types.includes(vehicle.type.label());
        }
    }

    clearAllFilters() {
        this.searchString = undefined;
        this.divisions = [];
        this.types = [];
    }
}
