import { VNode } from "vue";
import htmlBuilder from "@/helpers/HTMLBuilder";
import { api } from "@/api-client";

class ValidatableDirectiveHelpers {
    getPropertyName(vNode: VNode) {
        if (!vNode.props) {
            return null;
        }
        // vNode.props['onUpdate:modelValue']?.toString() is different for minimized app
        // minimized: d=>e.model.email=d
        // not minimized: ($event) => _ctx.model.email = $event
        let expression = vNode.props["onUpdate:modelValue"]
            ?.toString()
            .replace("$event => $setup.", "") // remove '($event) => _ctx.' from not minimized app expression
            .replace(" = $event", "") as string; // remove ' = $event' from not minimized app expression
        if (expression.indexOf("=>e.") >= 0) {
            expression = expression.substring(expression.indexOf("=>e.") + 4, expression.lastIndexOf("="));
        }
        if (expression.endsWith("=d")) {
            // for minimized app remove '=d' from end of expression ('d=>e.model.email=d')
            expression = expression.replace("=d", "") as string;
        }
        return expression.replace(/^(model.)/, "").replace(/^(request.)/, "");
    }

    addQuasarElementErrors(element: HTMLElement, propertyName: string, errors: string[] | null, validationErrors: api.ResultError) {
        if (element.classList.contains("app-datetime-picker")) {
            element = element.querySelector(".q-field") as HTMLElement;
        }

        element.classList.remove("q-field--error");
        element.classList.remove("q-field--highlighted");

        const qFieldControl = element.querySelector(".q-field__control");
        if (qFieldControl == null) {
            return;
        }

        qFieldControl.classList.remove("text-negative");

        let qFieldBottom = element.querySelector(".q-field__bottom");
        if (!qFieldBottom) {
            qFieldBottom = htmlBuilder.create("div").withClass("q-field__bottom row items-start q-field__bottom--animated").build();
            element.querySelector(".q-field__inner")?.append(qFieldBottom);
        }

        let qFieldMessages = qFieldBottom.querySelector(".q-field__messages");
        if (!qFieldMessages) {
            qFieldMessages = htmlBuilder.create("div").withClass("q-field__messages col").build();
            qFieldBottom.append(qFieldMessages);
        }
        qFieldMessages.innerHTML = "";

        if (errors && errors.length) {
            validationErrors.handleMessage(propertyName);

            element.classList.add("q-field--error");
            element.classList.add("q-field--highlighted");
            qFieldControl.classList.add("text-negative");

            errors.forEach((e) => {
                const qErrorMessage = htmlBuilder.create("div").withAttribute("role", "alert").withText(e).build();
                qFieldMessages?.append(qErrorMessage);
            });
        }
    }

    clearErrorMessageContainerAndAddErrorsToAlertElement(
        element: HTMLElement,
        alertElement: HTMLElement,
        errorMessageContainer: HTMLElement,
        propertyName: string,
        errors: string[] | null,
        validationErrors: api.ResultError
    ): HTMLElement {
        const errorList = alertElement.querySelector(".error-list");

        if (errorMessageContainer) {
            errorMessageContainer.remove();
        }

        if (errors && errors.length) {
            validationErrors.handleMessage(propertyName);

            errorMessageContainer = document.createElement("li");
            errors.forEach((e) => {
                const errorNode = document.createElement("span");
                errorNode.innerHTML = e;
                errorMessageContainer.appendChild(errorNode);
            });

            alertElement.classList.remove("d-none");
            errorList?.append(errorMessageContainer, element);
        } else if (alertElement.querySelector("li") == null) {
            alertElement.classList.add("d-none");
        }

        return errorMessageContainer;
    }

    clearErrorMessageContainerAndAddBootstrapErrorsToElement(
        element: HTMLElement,
        errorMessageContainer: HTMLElement,
        propertyName: string,
        errors: string[] | null,
        validationErrors: api.ResultError
    ): HTMLElement {
        if (errorMessageContainer) {
            errorMessageContainer.remove();
            element.className = element.className.replace("is-invalid", "");
        }

        if (errors && errors.length) {
            validationErrors.handleMessage(propertyName);

            element.className = element.className + " is-invalid";

            errorMessageContainer = document.createElement("div");
            errorMessageContainer.className = "invalid-feedback";
            errors.forEach((e) => {
                const errorNode = document.createElement("div");
                errorNode.innerHTML = e;
                errorMessageContainer.appendChild(errorNode);
            });

            element.parentElement?.insertBefore(errorMessageContainer, element);
        }

        return errorMessageContainer;
    }
}

const validatableDirectiveHelpers = new ValidatableDirectiveHelpers();
export default validatableDirectiveHelpers;
