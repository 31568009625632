import script from "./JobOrderTableFilters.vue?vue&type=script&lang=ts&setup=true"
export * from "./JobOrderTableFilters.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QChip});
