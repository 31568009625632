import { api } from "@/api-client";

export interface IMonitoredJobAndVehicleLocationSnapshotModel {
    monitoredJob: api.IMonitoredJobViewModel;
    vehicleLocationSnapshot?: api.IVehicleLocationSnapshotViewModel;
}

export class MonitoredJobAndVehicleLocationSnapshotModel implements IMonitoredJobAndVehicleLocationSnapshotModel {
    monitoredJob: api.IMonitoredJobViewModel;
    vehicleLocationSnapshot?: api.IVehicleLocationSnapshotViewModel;

    constructor(monitoredJob: api.IMonitoredJobViewModel, vehicleLocationSnapshot?: api.IVehicleLocationSnapshotViewModel) {
        this.monitoredJob = monitoredJob;
        this.vehicleLocationSnapshot = vehicleLocationSnapshot;
    }
}
