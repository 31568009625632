import { api } from "@/api-client";

export function translateParkingLotAlcometerStatus(alcometerStatus: api.ParkingLotAlcometerStatus) {
    switch (alcometerStatus) {
        case api.ParkingLotAlcometerStatus.None:
            return "Puudub";
        case api.ParkingLotAlcometerStatus.Inactive:
            return "Mitteaktiivne";
        case api.ParkingLotAlcometerStatus.Active:
            return "Aktiivne";
        default:
            alcometerStatus satisfies never;
    }
}

export function getAllParkingLotAlcometerOptions() {
    return Object.keys(api.ParkingLotAlcometerStatus)
        .filter((key) => isNaN(Number(key)))
        .map((key) => {
            const status = api.ParkingLotAlcometerStatus[key as keyof typeof api.ParkingLotAlcometerStatus];
            return {
                label: translateParkingLotAlcometerStatus(status),
                value: status,
            };
        });
}

export function getParkingLotAlcometerStatusBadge(alcometerStatus: api.ParkingLotAlcometerStatus) {
    switch (alcometerStatus) {
        case api.ParkingLotAlcometerStatus.None:
            return "badge-secondary";
        case api.ParkingLotAlcometerStatus.Inactive:
            return "badge-danger";
        case api.ParkingLotAlcometerStatus.Active:
            return "badge-success";
        default:
            alcometerStatus satisfies never;
    }
}
