import { api } from "@/api-client";

class IncidentStatusFlagTranslator {
    all() {
        return [
            { description: "Avatud", value: api.IncidentStatusFlag.Opened },
            { description: "Töös", value: api.IncidentStatusFlag.InProgress },
            { description: "Lõpetatud", value: api.IncidentStatusFlag.Completed },
            { description: "Kinnitatud", value: api.IncidentStatusFlag.Confirmed },
            { description: "Automaatselt kinnitatud", value: api.IncidentStatusFlag.AutomaticallyConfirmed },
            { description: "Kustutatud", value: api.IncidentStatusFlag.Deleted },
        ];
    }

    translate(value: api.IncidentStatusFlag) {
        return this.all().find((x) => x.value == value)?.description ?? "-";
    }
}

const incidentStatusFlagTranslator = new IncidentStatusFlagTranslator();
export default incidentStatusFlagTranslator;
