export class ListFilters {
    getAppliedFilters(appliedFilters: any | any[] | undefined, filterOptions: any[], optionValueName: string, optionLabelName: string): { label: string; value: string }[] {
        if (!appliedFilters) {
            return [];
        }

        const singleAppliedFilter = filterOptions.find((o) => o[optionValueName] == appliedFilters);
        if (singleAppliedFilter) {
            return [{ label: singleAppliedFilter[optionLabelName], value: singleAppliedFilter[optionValueName] }];
        }

        const multipleAppliedFilters = filterOptions.filter((o) => appliedFilters.some((f: any) => f == o[optionValueName]));
        return multipleAppliedFilters.map((o) => ({ label: o[optionLabelName], value: o[optionValueName] }));
    }

    removeAppliedFilters(filter: any, appliedFilters: any[]) {
        appliedFilters.splice(appliedFilters.indexOf(filter), 1);
    }
}
