import { api } from "@/api-client";
import Point = api.Point;

export function buildMapUrl(point: Point): string {
    return `https://www.openstreetmap.org/?mlat=${point.coordinates[0]}&mlon=${point.coordinates[1]}#map=16/${point.coordinates[0]}/${point.coordinates[1]}`;
}

export function pointToString(point: Point): string {
    return `${point.coordinates[0]}, ${point.coordinates[1]}`;
}
