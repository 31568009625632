import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";

export class ExternalServiceInterruptionNotification extends HubMessage<ExternalServiceInterruptionNotification> {
    showNotification!: boolean;

    public override overrideFromJSON(data: any): ExternalServiceInterruptionNotification {
        const result = new ExternalServiceInterruptionNotification();
        result.showNotification = data["showNotification"];
        return result;
    }
}

export type OnExternalServiceInterruptionsNotification = (message: ExternalServiceInterruptionNotification) => void;

export class ExternalServiceInterruptionsHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("external-service-interruptions-hub");
    }

    public async open(onNotify: OnExternalServiceInterruptionsNotification) {
        await super.onMessage(ExternalServiceInterruptionNotification, "Notify", onNotify);
        await super.start();

        return this;
    }

    public async close() {
        await super.stop();
    }
}
