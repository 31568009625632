import "./styles/quasar/quasar.scss";
import "@quasar/extras/material-symbols-outlined/material-symbols-outlined.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-symbols-outlined/material-symbols-outlined.css";
import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... })
export class QuasarUserOptions {
    // @ts-expect-error
    plugins: object;
    // @ts-expect-error
    config: object;
}
export const quasarUserOptions = {
    plugins: {
        Notify,
        AppFullscreen,
    },
    config: {
        notify: {},
    },
} as QuasarUserOptions;
