import script from "./TabModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./TabModal.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabs,QTabPanels});
