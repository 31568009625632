import { api } from "@/api-client";

export class FileParameter implements api.FileParameter {
    constructor(public input?: File) {
        // @ts-expect-error
        this.data = input;
        // @ts-expect-error
        this.fileName = input?.name;
    }

    data: Blob;
    fileName: string;
}
